import BreakForm from "components/modal/breakModal";
import ReturnBreakForm from "components/modal/returnBreakModal";
import TestModalDialog from "components/testModalDialog";
import { useEffect } from "react";
import { createPortal } from "react-dom";

const BreakModal = ({
  type = "break", //"break" or "return"
  handleCloseModal,
  attendanceFormData,
  shiftOptionList,
  isShow,
}) => {
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isShow]);

  return (
    !!isShow &&
    createPortal(
      <div className="flex fixed inset-0 z-50 justify-center items-center bg-black bg-opacity-50">
        <TestModalDialog
          title={type === "break" ? "Break" : "Return Break"}
          onClose={handleCloseModal}
          className="w-[700px]"
        >
          {type === "break" ? (
            <BreakForm
              onClose={handleCloseModal}
              attendanceFormData={attendanceFormData}
              shiftOptionList={shiftOptionList}
            />
          ) : (
            <ReturnBreakForm
              onClose={handleCloseModal}
              attendanceFormData={attendanceFormData}
              shiftOptionList={shiftOptionList}
            />
          )}
        </TestModalDialog>
      </div>,
      document.body
    )
  );
};

export default BreakModal;
