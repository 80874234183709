import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";
import dayjs from "dayjs";

const calculateDateRange = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const dateArray = [];

  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
};

const InputDateWeekPickerRange = ({
  name,
  setFetchDataDateRange,
  setFieldValue,
  disabled,
  width,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const start = dayjs();
  const end = start.add(7, "day");

  const [weekRange, setWeekRange] = useState({
    start: start,
    end: end,
  });
  const [dropdownActive, setDropdownActive] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDropdownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleWeekChange = (date) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Adjust to the start of the week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Adjust to the end of the week

    const dateValues = calculateDateRange(startOfWeek, endOfWeek);
    const dateValuesRange = dateValues.map((dateStr) => new Date(dateStr));

    setWeekRange({ start: startOfWeek, end: endOfWeek });
    setStartDate(new Date(startOfWeek)); // Ensure a new Date object is created
    setDropdownActive(false);
    setFetchDataDateRange({ startDate: startOfWeek, endDate: endOfWeek });

    setFieldValue(name, dateValuesRange); // Update the form field
  };

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  // const weekNumber = getWeekNumber(weekRange.start);

  function formatDateRange(start, end) {
    const options = { month: "long", day: "numeric" };
    const startDate = new Date(start).toLocaleDateString("en-US", options);
    const endDate = new Date(end).getDate();

    return `${startDate} - ${endDate}`;
  }

  const getHighlightedDates = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(weekRange.start);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    return dates;
  };


  return (
    <div className="relative w-full h-[60px]" ref={wrapperRef}>
      <div
        className="w-full h-full px cursor-pointer rounded-md bg-gray-100 flex justify-between items-center p-2"
        style={{ width }}
        onClick={() => setDropdownActive(!dropdownActive)}
      >
        {/* Previous Week Button */}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleWeekChange(
              new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000)
            );
          }}
          disabled={disabled}
          className=" w-[30px]"
        >
          <span className="text-lg">&lt;</span>
        </button>

        {/* Week Range Display */}
        <span
          className="flex items-center justify-center text-[12px] cursor-pointer w-full "
          onClick={() => setDropdownActive(!dropdownActive)}
        >
          {`${formatDateRange(weekRange.start, weekRange.end)}`}
        </span>

        {/* Next Week Button */}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleWeekChange(
              new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000)
            );
          }}
          disabled={disabled}
          className="w-[30px]"
        >
          <span className="text-lg">&gt;</span>
        </button>
      </div>

      {/* Date Picker Dropdown */}
      {dropdownActive && (
        <div className="absolute top-[100%] left-0 mt-2 z-50 shadow-md bg-white border">
          <DatePicker
            name={name}
            selected={startDate}
            onChange={handleWeekChange}
            showWeekNumbers
            showPopperArrow={false}
            inline
            dateFormat="MM/dd/yyyy"
            highlightDates={getHighlightedDates()}
            disabled={disabled}
            selectsStart
            shouldCloseOnSelect={true}
            dayClassName={(date) =>
              date >= weekRange.start && date <= weekRange.end
                ? "highlighted-day"
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

InputDateWeekPickerRange.propTypes = {
  name: PropTypes.string, // name for the input
  setFetchDataDateRange: PropTypes.func.isRequired, // callback to set the date range
  disabled: PropTypes.bool, // whether the input is disabled
};

InputDateWeekPickerRange.defaultProps = {
  name: "", // default to an empty string for the name
  disabled: false, // default to false for disabled
};

export default InputDateWeekPickerRange;
