import * as yup from "yup";

export const signUpNewSchema = yup.object().shape({
  // STEP ONE
  firstName: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    ),
  userName: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("Username is required"),
  gender: yup.string().required("Gender is Required"),
  religion: yup.string().required("Religion is Required"),
  dateOfBirth: yup.string().required("Date of Birth is Required"),
  maritalStatus: yup.string().required("Marital Status is Required"),
  race: yup.string().required("Race is Required"),
  bloodType: yup.string().required("Blood Type is Required"),
  phoneNumber: yup.number().min(3).required("Phone Number is Required"),
  // END STEP ONE

  // STEP TWO
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is Required"),

  password: yup.string().min(2).max(20).required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password value must be the same")
    .required("Confirm Password is Required"),
  postalCode: yup.number().min(3).required("Postal Code is Required"),
  country: yup.string().required("Country is Required"),
  // address: yup.string().min(3).required("Address is Required"),
  // END STEP TWO

  // STEP THREE
  identificationType: yup.string().required("Identification Type is Required"),
  identityExpiredDate: yup.string().when("$isPermanentDate", {
    is: false,
    then: (schema) => schema.required("Identity Expired Date is required"),
  }),
  nationality: yup.string().required("Nationality is Required"),
  identityNumber: yup
    .number()
    .test(
      "identityNumber",
      "Identity Number must be greater than 0",
      (val) => val > 0
    ),
  isPermanentDate: yup.boolean().default(false),
  covid19VactionStatus: yup
    .string()
    .required("Covid 19 Vaction Status is Required"),
  photo: yup
    .mixed()
    .test("fileSize", "The file is too large", (value) => {
      if (!value) return true;

      if (value || typeof value !== "string") {
        return value && value.file.size <= 2000000;
      }
      return true;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        if (!value) return true;
        if (value || typeof value !== "string") {
          return (
            value &&
            (value.file.type === "image/jpeg" ||
              value.file.type === "image/png" ||
              value.file.type === "image/jpg")
          );
        }
        return true;
      }
    ),

  attachments: yup
    .mixed()
    .required("You need to provide a file")
    .test("fileSize", "The file is too large", (value) => {
      if (typeof value !== "string") {
        return value && value.size <= 2000000;
      }
      return true;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        if (typeof value !== "string") {
          return (
            value &&
            (value.type === "image/jpeg" ||
              value.type === "image/png" ||
              value.type === "image/jpg")
          );
        }
        return true;
      }
    ),
  // END STEP THREE
});
