import * as yup from "yup";

export const registerSchema = yup.object().shape({
  // USER
  firstName: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("First Name is required"),
  lastName: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    ),
  userName: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("Username is required"),
  email: yup
    .string()
    .email("Must be a valid email")
    .required("Email is Required"),
  phoneNumber: yup.number().min(6).required("Phone Number is Required"),
  photo: yup
    .mixed()
    .required("Profile Picture is required")
    // .test("FileSize", "The file is too large", (value) => {
    //     return value && value.size <= 5000000;
    // })
    .test("FileSize", "The file is too large", (value) => {
      if (!value) return true; // No file uploaded, validation passes
      return value.size <= 5000000; // File size validation
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .png",
      (value) => {
        if (!value) return true; // No file uploaded, validation passes
        return (
          value.type === "image/jpeg" ||
          value.type === "image/png" ||
          value.type === "image/jpg"
        );
      }
    ),
  password: yup.string().min(2).max(20).required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password value must be the same")
    .required("Confirm Password is Required"),
  // COMPANY
  companyName: yup.string().min(6).required("Company Name is Required"),
  uenNumber: yup.string().min(6).required("Uen Number is Required"),
  address: yup.string().min(6).required("Address is Required"),
  employeeHeadCount: yup.string().required("Employee Head Count is Required"),
  industryField: yup.string().required("Industry Field is Required"),
  companyPhoneNumber: yup.number().min(2).required("Phone Number is Required"),
  postalCode: yup.number().min(4).required("Postal Code is Required"),
  country: yup.string().required("Country is Required"),
  companyImage: yup
    .mixed()
    .required("Company Logo is required")
    .test("fileSize", "The file is too large", (value) => {
      return value && value.size <= 2000000;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        return (
          value &&
          (value.type === "image/jpeg" ||
            value.type === "image/png" ||
            value.type === "image/jpg")
        );
      }
    ),
});
