import { forwardRef } from "react";
import ExpandEmployee from "../ExpandEmployee";
import AssignShiftData from "./AssignShiftData";
import ConflickComponent from "../ConflickComponent";
import HoverComponent from "../HoverComponent";
import { useShiftEmployee } from "../ShiftEmployeeContext";

const ShiftEmployee = forwardRef(
  (
    {
      forStructure,
      handleEditAssignShift,
      handleConflickAssign,
      roleUser,
      handleShowAssign,
      dataDate,
      handleRef,
      widthScroll,
      selectView,
      // handleExpandEmployee,
    },
    ref
  ) => {
    const { visibleStatus, toggleExpand } = useShiftEmployee();

    const handleWidth = () => {
      const widthScreen = window.innerWidth;

      // 1600:w-[73%] 1700:w-[76%] 1800:w-[78%]

      if (widthScreen <= 1600) {
        // return "w-[73%]";
        return "w-[71%]";
      } else if (widthScreen <= 1800) {
        return "w-[74%]";
      } else if (widthScreen <= 2000) {
        return "w-[76%]";
      } else if (widthScreen <= 2400) {
        return "w-[79%]";
      }
    };

    return (
      <div>
        {forStructure.length > 0 &&
          forStructure.map((item, dataIndex) => {
            const totalEmployees = item?.employeeAssignShift?.length;
            const isExpanded = visibleStatus[item.jobPosition] > 0;

            return (
              <div
                key={dataIndex}
                className={`overflow-auto flex-shrink-0   ${
                  item?.employeeAssignShift?.length > 0 ? "" : "hidden"
                }`}
              >
                {/* <div>{height}</div> */}
                {/* iF EMPLOYEE LENGTH > 0 show job position  */}
                <div
                  className={`flex flex-col w-full border-gray-400 border-b`}
                >
                  <div
                    className="flex items-center gap-1 w-[400px] h-[40px] px-3 hover:cursor-pointer"
                    onClick={() =>
                      toggleExpand(item?.jobPosition, totalEmployees)
                    }
                  >
                    <div>
                      <ExpandEmployee isExpanded={isExpanded} />
                    </div>

                    <h1 className="font-semibold">
                      {item?.jobPosition} ({item.employeeAssignShift.length}{" "}
                      Employees)
                    </h1>
                  </div>
                </div>

                <div>
                  {!isExpanded &&
                    item.employeeAssignShift.length > 0 &&
                    item.employeeAssignShift.map((itemEmp, empIdx) => {
                      const empJobPosition =
                        itemEmp?.userInformation?.employeementDetail
                          ?.jobPosition;

                      const totalExceedingHours =
                        itemEmp?.overTime?.overtimeDuration / 60 || 0;

                      return (
                        <div className="flex " key={empIdx}>
                          {/* <h1>employe idx : {empIdx}</h1> */}
                          <div
                            className={`border-r border-b  border-gray-400 min-w-[400px]  flex items-center pl-3 flex-shrink-0`}
                          >
                            {selectView === "Employee View" && (
                              <div className="ml-10 flex gap-4 items-center ">
                                <img
                                  src={itemEmp?.photo[0]?.link}
                                  alt="img"
                                  className="w-[40px] h-[40px] rounded-full"
                                />
                                <div className="flex flex-col h-full justify-between">
                                  <h1 className="font-semibold">
                                    {itemEmp?.firstName +
                                      " " +
                                      itemEmp?.lastName}
                                  </h1>
                                  <div
                                    className={`flex gap-2 items-center ${
                                      totalExceedingHours !== 0
                                        ? "text-red-500"
                                        : "text-[#A3A3AB]"
                                    }`}
                                  >
                                    <TimeCircle />
                                    <div>
                                      {itemEmp?.time}{" "}
                                      {totalExceedingHours !== 0
                                        ? totalExceedingHours.toFixed(1)
                                        : 0}{" "}
                                      Hours / Week
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {selectView === "Job Position View" &&
                              empIdx === 0 && (
                                <div className="ml-10 flex gap-4 items-center ">
                                  <div className=" pl-3 flex flex-col h-full justify-between">
                                    <h1 className="font-semibold">
                                      {empJobPosition}
                                    </h1>
                                    <div
                                      className={`flex gap-2 items-center ${
                                        totalExceedingHours !== 0
                                          ? "text-red-500"
                                          : "text-[#A3A3AB]"
                                      }`}
                                    >
                                      <TimeCircle />
                                      <div>
                                        {itemEmp?.time} {totalExceedingHours}{" "}
                                        Hours / Week
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>

                          {/*      itemEmp.isAnyConflick &&   itemEmp.lengthEveryAssign > 0 &&
                       itemEmp.lengthEveryAssign * 64  */}

                          <div
                            ref={(el) => {
                              // GET THE INDEX ARRAY IN ARRAY MAPPING USING REF2
                              if (!ref.current[dataIndex]) {
                                ref.current[dataIndex] = [];
                              }
                              ref.current[dataIndex][empIdx] = el;
                            }}
                            style={{
                              minHeight: itemEmp?.lengthEveryAssign * 66,
                            }}
                            className={`flex w-full overflow-hidden border-b border-gray-400  min-h-[${
                              itemEmp?.lengthEveryAssign * 64
                            }px]`}
                          >
                            {itemEmp.shiftData.length > 0 &&
                              itemEmp.shiftData.map((shift, index) => {
                                const assignShiftData =
                                  shift?.assignAndConflick;

                                return (
                                  <div
                                    key={index}
                                    className={`w-[180px] flex-shrink-0 h-[64px] p-1  border-r ${
                                      itemEmp?.lengthEveryAssign > 1 &&
                                      "border-b"
                                    } border-gray-400 flex-shrink-0`}
                                  >
                                    {/* <h1 className="text-red-500 text-[12px]">{`${shift.hoverBaseAssignShift}`}</h1> */}

                                    <div
                                      className={`${
                                        shift?.showHover
                                          ? "flex flex-col justify-center items-center "
                                          : "invisible"
                                      } `}
                                    >
                                      {assignShiftData.length > 0 &&
                                        assignShiftData?.map(
                                          (assign, idxAssign) => {
                                            const conflickData =
                                              assign?.conflickData;

                                            return (
                                              <div className="w-full ">
                                                <div className="w-full h-[50px] ">
                                                  <AssignShiftData
                                                    assignShift={assign}
                                                    allShift={
                                                      shift?.toUpdateAssignShift
                                                    }
                                                    employeeData={itemEmp}
                                                    key={idxAssign}
                                                    isAnyConflick={
                                                      itemEmp?.isAnyConflick
                                                    }
                                                    handleEditAssignShift={
                                                      handleEditAssignShift
                                                    }
                                                    handleConflickAssign={
                                                      handleConflickAssign
                                                    }
                                                    roleUser={roleUser}
                                                    selectView={selectView}
                                                  />
                                                </div>
                                                {/* conflick data */}
                                                <ConflickComponent
                                                  data={conflickData}
                                                  assignShift={assign}
                                                  handleConflickAssign={
                                                    handleConflickAssign
                                                  }
                                                  handleEditAssignShift={
                                                    handleEditAssignShift
                                                  }
                                                  allShift={
                                                    shift?.toUpdateAssignShift
                                                  }
                                                  employeeData={itemEmp}
                                                  selectView={selectView}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>

                                    <HoverComponent
                                      handleShowAssign={handleShowAssign}
                                      baseOnJobposition={
                                        shift?.filterBaseOnJobPosition
                                      }
                                      lengthAllAssignShift={
                                        itemEmp?.lengthAssign
                                      }
                                      shift={shift}
                                      itemEmp={itemEmp}
                                      roleUser={roleUser}
                                      allAssignShiftData={assignShiftData}
                                    />
                                  </div>
                                );
                              })}

                            <div
                              style={{
                                scrollBehavior: "smooth",
                              }}
                              className={`fixed  bottom-[8px] ${handleWidth()}  h-[25px] overflow-x-scroll  ${
                                dataDate.length === 0 && "hidden"
                              }`}
                              onScroll={handleRef}
                            >
                              <div
                                style={{
                                  minWidth: widthScroll,
                                }}
                                className=""
                              ></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
);

export default ShiftEmployee;

function TimeCircle() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8228 9.50039C16.8228 13.545 13.5445 16.8233 9.49992 16.8233C5.45529 16.8233 2.177 13.545 2.177 9.50039C2.177 5.45577 5.45529 2.17747 9.49992 2.17747C13.5445 2.17747 16.8228 5.45577 16.8228 9.50039Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2165 11.8298L9.23193 10.0493V6.21213"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
