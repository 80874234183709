import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventCountry: {
    name: "Indonesia",
    iso2: "ID",
    code: "62",
  },
  catchUId: "",
  showModal: false,
};

const eventDataSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventCountry: (state, action) => {
      state.eventCountry = {
        ...state.eventCountry,
        ...action.payload,
      };
    },

    setCatchUId: (state, action) => {
      state.catchUId = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});

export const { setEventCountry, setCatchUId, setShowModal } =
  eventDataSlice.actions;
export default eventDataSlice.reducer;
