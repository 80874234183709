import React, { useLayoutEffect, useRef } from "react";
import {
  InputSelect,
  SearchBox,
  InputDateRange,
  // InputDateWeekPickerRange,
} from "@bluesilodev/timhutcomponents";
// import { InputDateWeekPickerRange } from "components/testInputDateRange";
import InputDateWeekPickerRange from "components/inputDateWeek";

import { getCurrentWeekDates, handleChangeApp } from "utils/utils";
import { useDivRef } from "utils/context";

const ListInputSelect = ({
  employeeViewInput,
  setEmployeeViewInput,
  // dateValue,
  // setDateValue,
  // setFieldValue,
  // setDateRange,
  dataEmployees,
  keywordValue,
  setKeywordValue,
}) => {
  const inputRef = useRef();

  // dateValue, setDateValue, setFieldValue,
  const { dateValue, setDateValue, setFieldValue, setDateRange } = useDivRef();

  // Auto-focus SearchBox when keyword or employees data changes
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [keywordValue, dataEmployees]);

  const handleChange = (event) => {
    setEmployeeViewInput(event.target.value);
    // Log the selected view value correctly
  };

  const handleTodayDate = () => {
    const weekDates = getCurrentWeekDates();

    setDateValue(weekDates);
  };

  return (
    <>
      <div className="flex gap-3">
        {/* InputSelect for Employee View */}
        <InputSelect
          value={employeeViewInput}
          disabled={false} // Disabled as per the original code
          title={"View"}
          //   classname={"w-[200px]"}
          options={[
            { label: "Employee View", value: "Employee View" },
            { label: "Job Position View", value: "Job Position View" },
          ]}
          onChange={handleChange}
        />
        {/* 
        <InputDateRange
          name={"date"}
          value={dateValue}
          setFieldValue={setFieldValue}  // Updates the date range value
        /> */}
        <InputDateWeekPickerRange
          name={"date"}
          value={dateValue}
          setFetchDataDateRange={setDateRange}
          setFieldValue={setFieldValue} // Updates the date range value
        />
        <div className="[&>div>.rdrCalendarWrapper]:z-50">
          {/* <InputDateRange
            key={JSON.stringify(dateValue)}
            label="Time Range"
            name="date"
            error=""
            value={dateValue}
            setFieldValue={setFieldValue}
            className="min-w-[200px]"
          /> */}
        </div>

        {/* Today Button */}
        <button onClick={handleTodayDate}>
          <span className="w-[140px] h-[60px] bg-white flex justify-center items-center border-[1px] border-black rounded-md hover:bg-gray-400">
            <h1 className="text-sm">Today</h1>
          </span>
        </button>

        {/* Search Box for Keyword Filtering */}
        <SearchBox
          placeholder="Search"
          value={keywordValue} // Binds the search keyword state
          ref={inputRef} // Reference for auto-focusing
          onChange={(e) => setKeywordValue(e.target.value)} // Updates keyword on typing
        />
        <div className="min-w-[200px] h-[60px]"></div>
      </div>
    </>
  );
};

export default ListInputSelect;
