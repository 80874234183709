import React from "react";
import {
  InputText,
  InputSelect,
  Button,
  InputPassword,
  InputNumber,
} from "@bluesilodev/timhutcomponents";

import { useGetCSCQuery } from "services/commonAPI";
import { FiArrowRight } from "assets/icons";
import { useFormikContext } from "formik";

function StepTwo() {
  const { values, handleSubmit, handleBlur, errors, handleChange, touched } =
    useFormikContext();

  const { data: resCountry, isLoading: loadingCountry } = useGetCSCQuery();

  if (loadingCountry) {
    return <div>Loading...</div>;
  }

  const dataCountries =
    resCountry &&
    resCountry?.data?.data?.length > 0 &&
    resCountry.data?.data.map((item) => {
      return {
        label: item.name,
        value: item.country_code,
      };
    });

  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex flex-col w-full ">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-3">
            <h1 className="font-bold text-2xl font font-latoBold">
              User Credentials
            </h1>
            <InputText
              title={"Email Address"}
              placeholder="Enter Email Address"
              id="email"
              onChange={handleChange}
              value={values.email}
              required={true}
              label={null}
              onBlur={handleBlur}
              error={<div>{errors.email && touched.email && errors.email}</div>}
            />
            <InputText
              title={"Username"}
              id="userName"
              placeholder="Enter Username"
              required={true}
              onChange={handleChange}
              value={values.userName}
              label={null}
              onBlur={handleBlur}
              error={errors.userName && touched.userName && errors.userName}
            />
            <InputPassword
              id="password"
              title={"Password"}
              placeholder="Enter Password"
              required={true}
              onChange={handleChange}
              value={values.password}
              label={null}
              onBlur={handleBlur}
              error={errors.password && touched.password && errors.password}
            />
            
            <InputPassword
              id="confirmPassword"
              title={"Confirm Password"}
              placeholder="Enter Confirm Password"
              required={true}
              onChange={handleChange}
              value={values.confirmPassword}
              onBlur={handleBlur}
              error={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
            />
          </div>

          <div className="flex flex-col gap-3">
            <h1 className="font-bold text-2xl font font-latoBold">Address</h1>

            <div className="flex gap-3">
              <InputSelect
                options={dataCountries ? dataCountries : []}
                title={"Country"}
                name="country"
                value={values.country}
                required={true}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.country && touched.country && errors.country}
              />
              <InputText
                title={"Postal Code"}
                required={true}
                id="postalCode"
                placeholder="Enter Postal Code"
                type={"number"}
                onChange={handleChange}
                value={values.postalCode}
                onBlur={handleBlur}
                label={null}
                error={
                  <div>
                    {errors.postalCode &&
                      touched.postalCode &&
                      errors.postalCode}
                  </div>
                }
              />
            </div>
          </div>

          <textarea
            id="address"
            onChange={handleChange}
            value={values.address}
            // onBlur={handleBlur}
            placeholder="Address"
            className="w-full border-[1px] border-black resize-none h-[100px] rounded-md outline-none p-3"
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default StepTwo;
