import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo } from "react";
import { cn } from "utils/common";
import { useDispatch } from "react-redux";
import { setShowModal, setCatchUId } from "store/reducer/event";
import CalendarEvent from "assets/CalendarEvent.svg";

const Tabs = ({
  options,
  value,
  width,
  onClick,
  day,
  currentTab,
  onClickList,
  setCurrentTab,
  ...props
}) => {
  const handleClick = (e) => {
    setCurrentTab(e.target.id);
    if (onClick) onClick(e); // Call the onClick prop if it is provided
  };

  const dispatch = useDispatch();
  return (
    <div
      className="flex flex-col w-full gap-1  max-h-[450px] overflow-auto"
      {...props}
    >
      <ul className="flex justify-around w-full gap-1 p-1 text-xs font-medium text-center text-gray-500 border border-black rounded-md dark:text-gray-400">
        {options.map((option) => (
          <TabButton
            key={option.id}
            id={option.id}
            value={option.value}
            onClick={handleClick}
            currentTab={currentTab}
          />
        ))}
      </ul>
      <div className="w-full p-1 overflow-y-auto rounded-lg">
        {options &&
          options.length > 0 &&
          options.map((option) => {
            if (currentTab !== option.id) return null;

            // Filter event dan holiday yang sesuai dengan tanggal (jika day dipilih)
            const filteredEvents =
              (option?.dataList.length > 0 &&
                option?.dataList?.filter((dataItem) => {
                  if (!day) return true;
                  const itemDate = dayjs(dataItem.date).format(
                    option.id === "3" ? "DD-MM" : "DD-MM-YYYY"
                  );
                  const selectedDate = dayjs(day).format(
                    option.id === "3" ? "DD-MM" : "DD-MM-YYYY"
                  );

                  return itemDate === selectedDate;
                })) ||
              [];

            const filteredHolidays =
              (option &&
                option.holidayList &&
                option.holidayList.length > 0 &&
                option?.holidayList?.filter((dataItem) => {
                  if (!day) return true;
                  const itemDate = dayjs(dataItem.date).format(
                    option.id === "3" ? "DD-MM" : "DD-MM-YYYY"
                  );
                  const selectedDate = dayjs(day).format(
                    option.id === "3" ? "DD-MM" : "DD-MM-YYYY"
                  );

                  return itemDate === selectedDate;
                })) ||
              [];

            return (
              <div key={option.id}>
                {/* Tampilkan EVENT hanya jika ada data */}
                {filteredEvents.length > 0 && (
                  <div>
                    {filteredEvents.map((dataItem, index) => {
                      const itemDate = dayjs(dataItem.date);
                      return (
                        <div
                          key={index}
                          className={cn(
                            "flex w-full gap-2 p-2 mb-1 border-2 border-gray-100",
                            dataItem?.uId && "cursor-pointer"
                          )}
                          onClick={() => {
                            if (!dataItem?.uId) return;
                            // alert("EVENT : ", dataItem.uId);
                            // onClickList(dataItem.uId);
                            dispatch(setCatchUId(dataItem.uId));
                            dispatch(setShowModal(true));
                          }}
                        >
                          <div className="relative flex justify-center w-10 h-10 border-2 border-gray-200 rounded-full">
                            <img
                              src={CalendarEvent}
                              alt="Calendar Event Icon"
                              className="p-1"
                            />
                            <p className="absolute text-[11px] font-bold text-gray-400 transform -translate-x-1/2 -translate-y-[35%] top-1/2 left-1/2 cursor-default">
                              {itemDate.isValid() && itemDate.format("DD")}
                            </p>
                          </div>
                          <div className="flex flex-col justify-between w-3/4">
                            <p className="mb-1 text-sm font-semibold line-clamp-1">
                              {option.id === "2"
                                ? `${dataItem.name} - ${dataItem.employeeName}`
                                : dataItem.name}
                            </p>
                            <p className="text-xs text-gray-400">
                              {itemDate.format("DD MMMM YYYY")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* Tampilkan HOLIDAY hanya jika ada data */}
                {filteredHolidays.length > 0 && (
                  <div>
                    {filteredHolidays.map((dataItem, index) => {
                      const itemDate = dayjs(dataItem.date);
                      return (
                        <div
                          key={index}
                          className="flex w-full gap-2 p-2 mb-1 border-2 border-gray-100"
                        >
                          <div className="relative flex justify-center w-10 h-10 border-2 border-gray-200 rounded-full">
                            <img
                              src={CalendarEvent}
                              alt="Calendar Event Icon"
                              className="p-1"
                            />
                            <p className="absolute text-[11px] font-bold text-gray-400 transform -translate-x-1/2 -translate-y-[35%] top-1/2 left-1/2 cursor-default">
                              {itemDate.isValid() && itemDate.format("DD")}
                            </p>
                          </div>
                          <div className="flex flex-col justify-between w-3/4">
                            <p className="mb-1 text-sm font-semibold line-clamp-1">
                              {option.id === "2"
                                ? `${dataItem.name} - ${dataItem.employeeName}`
                                : dataItem.name}
                            </p>
                            <p className="text-xs text-gray-400">
                              {itemDate.format("DD MMMM YYYY")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const TabButton = memo(({ id, value, onClick, currentTab }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`inline-block py-3 rounded-md flex-1 font-semibold ${
        currentTab === id
          ? "bg-[#dd7224] text-white"
          : "bg-gray-300 text-gray-500"
      } hover:text-white hover:bg-[#dd7224]`}
    >
      {value}
    </button>
  );
});

Tabs.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  onClick: PropTypes.func,
  day: PropTypes.string,
};

Tabs.defaultProps = {
  options: [],
  value: "",
  onClick: () => {},
  day: "",
};

export default memo(Tabs);
