import { useQuery } from "@tanstack/react-query";

import { customAxios } from "utils/axios";

const useGetEmployeeShiftQuery = (props = {}) => {
  const { firstName, locationID, jobPosition, department, startDate, endDate } =
    props;

  const getEmployee = async () => {
    const queryParams = new URLSearchParams();

    if (firstName) queryParams.append("firstName", firstName);
    if (locationID) queryParams.append("locationID", locationID);
    if (jobPosition) queryParams.append("jobPosition", jobPosition);
    if (department) queryParams.append("departmentName", department);
    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);

    // Build the URL based on whether there are query parameters
    const url = queryParams.toString()
      ? `/api/shift/getShiftData/employee?${queryParams}`
      : "/api/shift/getShiftData/employee";

    const { data } = await customAxios.get(url);
    return data;
  };

  return useQuery(
    ["getEmployee", firstName, locationID, jobPosition],
    getEmployee,
    {
      onError: (err) => {
        console.log("Error fetching Employee Data ", err);
      },
    }
  );
};

const useGetLocationQuery = () => {
  return useQuery(
    ["getLocation"],
    async () => {
      const { data } = await customAxios.get("/api/shift/employee/location");
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching Location Data ", err);
      },
    }
  );
};

const useGetShiftQuery = () => {
  return useQuery(
    ["getAllShift"],
    async () => {
      const { data } = await customAxios.get("/api/shift");
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching all shift Data", err);
      },
    }
  );
};

const useGetAttendanceByShift = (shiftId) => {
  return useQuery(
    ["getShift"],
    async () => {
      const { data } = await customAxios.get(`/api/shift/${shiftId}`);
      return data;
    },
    {
      onError: (err) => {
        console.log("Error fetching shift Data", err);
      },
    }
  );
};

const useGetDashboardQuery = ({ fromDate, toDate }) => {
  const getDashboard = async () => {
    try {
      let queryParams = "";

      // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
      // if (fromDate) queryParams += `fromDate=${fromDate}&`;
      // if (toDate) queryParams += `toDate=${toDate}&`;

      if (fromDate) queryParams += `fromDate=${fromDate}&`;
      if (toDate) queryParams += `toDate=${toDate}&`;

      if (queryParams.endsWith("&")) {
        queryParams = queryParams.slice(0, -1);
      }

      const url = queryParams
        ? `/api/shift/assignshift/dashboard?${queryParams}`
        : `/api/shift/assignshift/dashboard`;

      const { data } = await customAxios.get(url);

      return data;
    } catch (error) {
      throw error;
    }
  };

  const queryKey = ["getDashboard"];
  if (fromDate) queryKey.push(`fromDate=${fromDate}`);
  if (toDate) queryKey.push(`toDate=${toDate}`);

  return useQuery({
    queryKey: queryKey,
    queryFn: getDashboard,
    onError: (err) => {
      console.log("Error fetching dashboard data: ", err);
    },
  });
};

// const useGetDashboardQuery = ({ fromDate, toDate }) => {
//   return useQuery(
//     ["getDashboard", fromDate, toDate], // Add dates to the query key for caching
//      ,
//     {
//       onError: (err) => {
//         console.log("Error fetching dashboard data: ", err);
//       },
//       enabled: !!fromDate && !!toDate, // Only run query if dates are provided
//     }
//   );
// };

export const useGetByIdShift = ({ uId }) => {
  const getByIdShift = async () => {
    const resp = await customAxios.get(`/api/shift/${uId}`);

    return resp.data;
  };

  return useQuery({
    queryKey: ["getShiftByID"],
    queryFn: getByIdShift,
    enabled: !!uId,
    onError: (err) => {
      console.log("Something Wrong ", err);
    },
  });
};

const API = `api/scheduling`;

export const useGetSchedulingShiftByUserID = (userID) => {
  return useQuery(["getSchedulingShift"], async () => {
    const res = await customAxios.get(`${API}/get-scheduling/${userID}`);
    return res.data;
  });
};

const getAttendanceByShift = async (shiftId) => {
  try {
    const { data } = await customAxios.get(`/api/shift/${shiftId}`);
    return data;
  } catch (error) {
    console.error("Error fetching shift data", error);
    throw error;
  }
};

export {
  useGetEmployeeShiftQuery,
  useGetLocationQuery,
  useGetShiftQuery,
  useGetAttendanceByShift,
  useGetDashboardQuery,
  getAttendanceByShift,
};
