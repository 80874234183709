import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    currentRole: "",
    token: "",
  },
  reducers: {
    setLoginReducer: (state, action) => {
      state.currentUser = action.payload.currentUser;
      state.token = action.payload.token;
      // console.log(action.payload.currentUser);
      // state.currentRole = action.payload.currentUser.role[0];

      state.currentRole = action.payload.currentRole;
    },
    setCurrentUserRole: (state, action) => {
      if (state.currentUser) {
        state.currentUser = {
          ...state.currentUser,
          role: action.payload.currentRole, // Update only the role
        };
        state.currentRole = action.payload?.currentRole?.[0];
      }
    },

    setLogoutReducer: (state) => {
      state.currentUser = null;
      state.currentRole = "";
      state.token = "";
    },
    setRoleReducer: (state) => {
      if (state.currentRole === "Admin") {
        state.currentRole = state.currentUser.role.includes("Supervisor")
          ? "Supervisor"
          : "Employee";
      } else {
        if (state.currentUser.role.includes("Admin")) {
          state.currentRole = "Admin";
        }
      }
    },
  },
});

export const {
  setLoginReducer,
  setCurrentUserRole,
  setLogoutReducer,
  setRoleReducer,
} = userSlice.actions;

export default userSlice.reducer;
