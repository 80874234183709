import { useEffect, useState } from "react";
import { formatTime } from "utils/utils";
import { clearInterval, setInterval } from "worker-timers";

function Stopwatch({ startFrom = null, isTimerRunning }) {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    function updateTimer() {
      if (!isTimerRunning) return;

      const now = Date.now();
      setTimer(Math.floor((now - new Date(startFrom).getTime()) / 1000));
    }

    const updateTimerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(updateTimerInterval);
  }, [isTimerRunning, startFrom]);

  const formattedTimer =
    isTimerRunning && timer !== null ? formatTime(timer) : "-- : -- : --";
  return <p className="text-lg font-bold">{formattedTimer}</p>;
}

export default Stopwatch;
