import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import AdminDashboardPage from "pages/admin/dashboard";
import EmployeeDashboardPage from "pages/user/dashboard";

import { useGetAppQuery } from "services/commonAPI";
import { useGetLeaveQuery } from "services/leaveAPI";
import {
  useGetEventQuery,
  useGetBirthdayQuery,
  useGetAllHoliday,
} from "services/employeeAPI";
// import EventCalendarIcon from "assets/eventcalendar.svg";
import EventCalentDate from "assets/icons/eventCalentDate";
import { formatToMidnight } from "utils/common";
import { useDivRef } from "utils/context";

import dayjs from "dayjs";

import ModalEvent from "shared/modalEventForm";

const allowedPaths = ["/resetPassword", "/confirmEmail", "/forgotPassword"];

const DashboardPage = () => {
  const location = useLocation();
  const { currentUser: stateCurrentUser, currentRole: stateCurrentRole } =
    useSelector((state) => state.userData);

  // Check if the current path is allowed
  const isAllowedPath = allowedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const { eventCountry, catchUId, showModal } = useSelector(
    (state) => state.event
  );

  // Fallback to localStorage if stateCurrentUser is null
  const storedUser = stateCurrentUser
    ? null
    : localStorage.getItem("currentUser");
  const currentUser =
    stateCurrentUser || (storedUser ? JSON.parse(storedUser) : null);
  const currentRole =
    stateCurrentRole || (currentUser?.role ? currentUser.role[0] : "");
  const [currentTab, setCurrentTab] = useState("1");
  const [dateData, setDateData] = useState(undefined);
  // const { dateData, setDateData } = useDivRef();

  const { data: apps } = useGetAppQuery();

  const [dashboardData, setDashboardData] = useState({
    eventData: [],
    birthdayData: [],
    leaveData: [],
  });

  const { data: eventRespData, refetch: refetchEvent } = useGetEventQuery(
    dayjs(dateData).date(1).format("YYYY-MM-DD")
  );

  const {
    data: holidayData,
    isSuccess: successHoliday,
    refetch: refetchHoliday,
  } = useGetAllHoliday({
    country: "",
    date: "",
  });

  const { data: birthdayRespData, refetch: refetchBirthday } =
    useGetBirthdayQuery(dateData);
  const { data: leaveRespData, refetch: refetchLeave } = useGetLeaveQuery();

  useEffect(() => {
    if (eventRespData && eventRespData.success && successHoliday) {
      const filterHolidayData = holidayData?.data?.data.filter((hol) => {
        return (
          hol.country === eventCountry.name &&
          dayjs(dateData).format("MMYYYY") ===
            dayjs(hol.startDate).format("MMYYYY")
        );
      });

      const filterEventData = eventRespData?.data?.data.filter((hol) => {
        return hol?.location?.country === eventCountry.iso2;
      });

      setDashboardData((prevData) => ({
        ...prevData,
        eventData: filterEventData,
        holidayData: filterHolidayData,
      }));
    }
    if (birthdayRespData && birthdayRespData.success) {
      // console.log("birthday data", birthdayRespData);
      setDashboardData((prevData) => ({
        ...prevData,
        birthdayData: birthdayRespData?.data?.data,
      }));
    }
    if (leaveRespData && leaveRespData.success) {
      // console.log("leave data", leaveRespData);
      setDashboardData((prevData) => ({
        ...prevData,
        leaveData: leaveRespData?.data?.data,
      }));
    }

    refetchEvent();
  }, [
    eventRespData,
    birthdayRespData,
    leaveRespData,
    holidayData,
    eventCountry,
    refetchEvent,
  ]);

  // useEffect(() => {
  // Refetch calendar data when the current month changes
  // refetchEvent();
  // refetchLeave();
  // refetchHoliday();
  // }, [refetchEvent, refetchBirthday, refetchLeave]);

  const exampleOptions = useMemo(() => {
    const allHolidayEvent =
      dashboardData?.holidayData?.length > 0 &&
      dashboardData?.holidayData.map((event) => {
        return {
          name: event.holidayName,
          date: event.startDate,
          country: event?.country,
        };
      });

    const transformedEventData =
      dashboardData?.eventData?.length > 0
        ? dashboardData?.eventData?.map((event) => {
            return {
              uId: event.uId,
              name: event.nameEvent,
              date: formatToMidnight(event.startDate),
              country: event?.location?.country,
            };
          })
        : [];

    const transformedBirthdayData =
      dashboardData?.birthdayData?.length > 0
        ? dashboardData?.birthdayData?.map((birthday) => {
            const getDate = new Date(birthday.dateOfBirth).getUTCDate();

            return {
              imgSrc: <EventCalentDate date={getDate} />,
              name: birthday.name,
              date: formatToMidnight(birthday.dateOfBirth),
            };
          })
        : [];

    const transformedLeaveData =
      dashboardData?.leaveData?.length > 0
        ? dashboardData?.leaveData?.map((leave) => {
            // Get the newest date from leaveTimes
            const latestLeaveTime = leave.leaveTimes.reduce(
              (latest, current) => {
                return new Date(current.date) > new Date(latest.date)
                  ? current
                  : latest;
              },
              leave.leaveTimes[0]
            );

            const getDate = new Date(latestLeaveTime.date).getUTCDate();

            return {
              imgSrc: <EventCalentDate date={getDate} />,
              name: leave.leaveType.name,
              date: formatToMidnight(latestLeaveTime.date), // latestLeaveTime.date,
              employeeName: leave.employeeInformation.firstName,
            };
          })
        : [];

    return [
      {
        id: "1",
        value: "Events/Holiday",
        dataList: transformedEventData,
        holidayList: allHolidayEvent,
      },
      {
        id: "2",
        value: "On Leave",
        dataList: transformedLeaveData,
      },
      {
        id: "3",
        value: "Birthday",
        dataList: transformedBirthdayData,
      },
    ];
  }, [dashboardData, eventRespData]);

  if (!currentUser && !isAllowedPath) {
    // Redirect to login if no current user
    // console.log("No current user, redirecting to login");
    return <Navigate to="/login" />;
  }

  return (
    <div>
      {currentRole?.includes("Admin") ? (
        <AdminDashboardPage
          currentUserName={currentUser.userName}
          setDateData={setDateData}
          exampleOptions={exampleOptions}
          day={dateData}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          // currentMonth={currentMonth}
          linkApps={apps}
        />
      ) : (
        <EmployeeDashboardPage
          currentUserName={currentUser?.userName}
          setDateData={setDateData}
          exampleOptions={exampleOptions}
          day={dateData}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          linkApps={apps}
          // currentMonth={currentMonth}
        />
      )}

      {showModal && !!catchUId && <ModalEvent />}
    </div>
  );
};

export default DashboardPage;
