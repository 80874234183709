import { useQuery, useMutation } from "@tanstack/react-query";

import { customAxios } from "utils/axios";
import { alertError, alertSuccess } from "utils/alert";
import dayjs from "dayjs";

const useGetAllHoliday = (props) => {
  const { date, country } = props;
  const API = "/api/event/holiday/all";

  const allHoliday = async () => {
    let queryParams = "";

    // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
    if (date) queryParams += `date=${date}&`;
    if (country) queryParams += `country=${country}&`;

    if (queryParams.endsWith("&")) {
      queryParams = queryParams.slice(0, -1);
    }
    const url = queryParams ? `${API}?${queryParams}` : API;

    const { data } = await customAxios.get(url);
    return data;
  };

  const queryKey = ["getAllHoliday"];
  if (date) queryKey.push(`date=${date}`);
  if (country) queryKey.push(`country=${country}`);

  return useQuery({
    queryKey: queryKey.length > 0 ? queryKey : ["getAllHoliday"],
    queryFn: allHoliday,
  });
};

export const postRegisterUserInformation = async (body) => {
  try {
    const res = await customAxios.post(
      `/api/employee/registerUserInformationModel`,
      body
    );

    return res.data;
  } catch (error) {
    console.log("ERROR : ", error);
  }
};

export const postRegisterUser = async (body) => {
  try {
    const res = await customAxios.post(`/api/employee/registerUserModel`, body);
    return res.data;
  } catch (error) {
    console.log("ERROR : ", error);
  }
};

// END NEW SIGN UP

const handlePostRegisterEmployee = async (val) => {
  try {
    const res = await customAxios.post(`/api/employee/registerEmployee`, val);
    return res.data;
  } catch (error) {
    console.error("Register User Error:", error);
    alertError(error.response?.data?.message ?? "Failed to Register");
    throw error;
  }
};

const handlePostRegisterCompany = async (val) => {
  try {
    const res = await customAxios.post(`/api/employee/registerCompany`, val);
    return res.data;
  } catch (error) {
    console.error("Register Company Error:", error);
    alertError(error.response?.data?.message ?? "Failed to Register");
    throw error;
  }
};

const handlePostLogin = async (val) => {
  try {
    const res = await customAxios.post(`/api/auth/login`, val);
    return res.data;
  } catch (error) {
    console.error("Login error:", error);
    alertError(error.response?.data?.message ?? "Failed to login");
    throw error;
  }
};

const handlePostForgotPassword = async (val) => {
  try {
    const res = await customAxios.post(`/api/auth/forgotPassword`, val);
    return res.data;
  } catch (error) {
    console.error("Forgot Password error:", error);
    alertError(
      error.response?.data?.message ?? "Failed to Send Forgot Password Data"
    );
    throw error;
  }
};

const handlePostResetPassword = async (val) => {
  try {
    const res = await customAxios.post(`/api/auth/resetPassword`, val);
    return res.data;
  } catch (error) {
    console.error("Reset Password error:", error);
    alertError(
      error.response?.data?.message ?? "Failed to Send Reset Password Data"
    );
    throw error;
  }
};

const handlePostEvent = async (val) => {
  try {
    const res = await customAxios.post(`/api/event`, val);
    return res.data;
  } catch (error) {
    console.error("Event create error:", error);
    alertError(error.response?.data?.message ?? "Failed to Create Event");
    throw error;
  }
};

export const handleUpdateEvent = async ({ id, val, onSuccess, onError }) => {
  try {
    const res = await customAxios.put(`/api/event/${id}`, val);

    if (res.data) {
      onSuccess?.(res.data);
    }
    return res.data;
  } catch (error) {
    console.error("Event update error:", error);
    onError?.(error.response?.data?.message ?? "Failed to Update data");
    throw error;
  }
};

export const handleDeleteEvent = async ({ id, onSuccess, onError }) => {
  try {
    const res = await customAxios.delete(`/api/event/${id}`);

    if (res.data) {
      onSuccess?.(res.data);
    }

    return res.data;
  } catch (error) {
    console.error("Delete error:", error);
    onError?.(error.response?.data?.message ?? "Failed to delete item");
    throw error;
  }
};

const useGetAllEmployeesQuery = () => {
  return useQuery(["getAllEmployees"], async () => {
    const { data } = await customAxios.get(`/api/employeeuser`);
    return data;
  });
};

const useGetEventQuery = (date) => {
  return useQuery(["getEvent", date], async () => {
    const { data } = await customAxios.get(`/api/event/thisMonth?date=${date}`);
    return data;
  });
};

const useGetOneEvent = (id) => {
  return useQuery(
    ["getOneEvent", id],
    async () => {
      const { data } = await customAxios.get(`/api/event/${id}`);
      return data;
    },
    {
      enabled: !!id,
    }
  );
};

const useGetBirthdayQuery = (queryDate) => {
  const date = dayjs(queryDate).date(1);
  return useQuery(["getBirthday", date.format("M")], async () => {
    const { data } = await customAxios.get(
      `/api/employeeuser/hbdEmployee?date=${date.format("YYYY-MM-DD")}`
    );
    return data;
  });
};

const useGetLocationQuery = () => {
  return useQuery(["getLocation"], async () => {
    const { data } = await customAxios.get(`/api/employee/getLocation`);
    return data;
  });
};

const useGetSettingByDefaultQuery = ({ onSuccess, onError }) => {
  return useQuery({
    queryKey: ["getSettingByDefault"],
    queryFn: async () => {
      const res = await customAxios.get(`/api/employee/getCompanySetting`);
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export {
  useGetEventQuery,
  useGetBirthdayQuery,
  useGetLocationQuery,
  useGetAllEmployeesQuery,
  handlePostLogin,
  handlePostRegisterCompany,
  handlePostRegisterEmployee,
  handlePostForgotPassword,
  handlePostResetPassword,
  handlePostEvent,
  useGetSettingByDefaultQuery,
  useGetAllHoliday,
  useGetOneEvent,
};
