import React from "react";
import Chart from "react-apexcharts";

// ProgressBar Component
const ProgressBar = ({ stages }) => {

  return (
    <div className="flex w-full h-3 overflow-hidden bg-gray-200 rounded-full">
      {stages.map((stage, index) => (
        <div
          key={index}
          className="h-full"
          style={{
            width: `${stage.progress}%`,
            backgroundColor: stage.color,
            borderRadius:
              index === 0
                ? "3px 0 0 3px"
                : index === stages.length - 1
                ? "0 3px 3px 0"
                : "none",
          }}
        />
      ))}
    </div>
  );
};

// DonutChart Component
const DonutChart = ({ data }) => {
  const options = {
    chart: {
      type: "donut",
      height: 150,
      width: 140,
    },
    plotOptions: {
      pie: {
        donut: {
          size: 50,
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -8,
              formatter: (val) => val,
            },
            value: {
              show: true,
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: undefined,
              offsetY: 0,
              formatter: (val) => val,
            },
            total: {
              show: true,
              showAlways: false,
              label: "Total",
              fontSize: "10px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: "#373d3f",
              formatter: (w) =>
                w.globals.seriesTotals.reduce((a, b) => a + b, 0),
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        fontSize: "10px",
      },
    },
  };

  const series = data.map((item) => item.value);
  const colors = data.map((item) => item.color);

  return (
    <Chart options={{ ...options, colors }} series={series} type="donut" />
  );
};

// ColorLabel Component
const ColorLabel = ({ attendanceSummary }) => {
  const getRoundedValue = (value) => (value ? Math.round(value) : 0);

  const labels = [
    {
      color: "#4BD394",
      key: "onTimePercentage",
      label: "On Time",
    },
    {
      color: "#B83C3C",
      key: "latePercentage",
      label: "Late",
    },
    {
      color: "#EFCA4F",
      key: "noPunchInOutPercentage",
      label: "Absence",
    },
    {
      color: "#A3A3AB",
      key: "leavePercentage",
      label: "On Leave",
    },
  ];

  return (
    <div className="flex justify-between w-full p-1">
      {labels.map(({ color, key, label }) => (
        <div key={key} className="flex items-start justify-center gap-1">
          <div className="flex items-center mt-1 aspect-square">
            <div
              className="w-2 rounded-full aspect-square"
              style={{ backgroundColor: color }}
            />
          </div>
          <div className="flex flex-col">
            <p className="text-xs font-semibold">
              {getRoundedValue(attendanceSummary?.percentages?.[key])}%
            </p>
            <p className="text-xs">{label}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export { ProgressBar, DonutChart, ColorLabel };
