import { Header } from "@bluesilodev/timhutcomponents";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import { useGetAppQuery } from "services/commonAPI";
import { setLogoutReducer, setRoleReducer } from "store/reducer/user";
import { handleChangeApp } from "utils/utils";

import { LogoTimhut } from "assets/icons";

const LayoutDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, currentRole } = useSelector((state) => state.userData);

  if (!currentUser) {
    navigate("/login");
  }

  if (currentUser?.role?.includes("SuperAdmin")) {
    window.location.href = "/employee/super/organization";
  }

  const [apps, setApps] = useState([]);

  const { data: resApp, isSuccess: isSuccessApp } = useGetAppQuery();

  useEffect(() => {
    if (isSuccessApp && resApp.data) {
      setApps(resApp.data?.data);
    }
  }, [isSuccessApp, resApp]);

  useEffect(() => {
    // Update localStorage whenever currentRole changes
    if (currentRole) {
      localStorage.setItem("currentRole", currentRole);
    }
  }, [currentRole]);

  const onClickLogout = () => {
    dispatch(setLogoutReducer());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");
    navigate("/login");
  };

  const onClickSwitch = () => {
    dispatch(setRoleReducer());
  };

  const onClickApp = (link) => {
    handleChangeApp(link);
  };

  const onClickMyAccount = (link) => {
    handleChangeApp(`${link}/myAccount`);
  };

  const onClickSetting = (link) => {
    handleChangeApp(`${link}`);
  };

  const cleanedRole = currentRole.trim().toLowerCase();
  const moduleDisabled = cleanedRole === "superadmin";

  return (
    <div className={`p-5`}>
      {apps.length > 0 && (
        <Header
          title={<LogoTimhut />}
          apps={apps}
          switchDisabled={currentUser?.role?.length < 2}
          userData={{
            name: currentUser?.userName,
            role: currentRole,
            language: "English",
            switchRole:
              currentRole === "Admin"
                ? currentUser?.role.includes("Supervisor")
                  ? "Supervisor"
                  : "Employee"
                : currentUser?.role.includes("Admin")
                ? "Admin"
                : currentRole,
            image:
              currentUser?.photo?.length > 0 ? currentUser?.photo[0].link : "",
          }}
          onSwitch={onClickSwitch}
          onClickMyAccount={onClickMyAccount}
          onClickSetting={onClickSetting}
          onClickLogout={onClickLogout}
          onClickApp={onClickApp}
          moduleDisabled={moduleDisabled}
          // notificationData={notifications}
        />
      )}
      <Outlet />
    </div>
  );
};

export default LayoutDashboard;
