import EventForm from "components/modal/EventModal";
import Tabs from "components/tabs";
import TestModalDialog from "components/testModalDialog";
import {
  BreakWidget,
  DataWidget,
  PunchWidget,
  ShiftScheduling,
} from "pages/user/widgets";
import { useMemo, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setCatchUId, setShowModal } from "store/reducer/event";
// import TestModalDialog from "components/testModalDialog";
import CustomCalendar from "components/calendar";
import CustomCalendarData from "pages/dashboard/customCalendar";
import dayjs from "dayjs";
import { useGetLocationQuery } from "services/employeeAPI";
import { useGetSchedulingShiftByUserID } from "services/schedulingAPI";
import BreakModal from "./BreakModal";
import PunchModal from "./PunchModal";
import { ButtonSelectCountry } from "pages/admin/dashboard/buttonClickDate";

const EmployeeDashboardPage = ({
  currentUserName,
  setDateData,
  exampleOptions,
  day,
  currentTab,
  setCurrentTab,
}) => {
  const { currentUser: loggedUser } = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState({
    punchModal: false,
    breakModal: false,
    punchOutModal: false,
    returnBreakModal: false,
  });
  const [showTabsForm, setShowTabsForm] = useState(false);
  const { data: resLocation } = useGetLocationQuery();

  const { data: resScheduling } = useGetSchedulingShiftByUserID(
    loggedUser?.uId || ""
  );

  const handleOpenModal = (modalKey) => {
    setModalShow({
      punchModal: false,
      breakModal: false,
      punchOutModal: false,
      returnBreakModal: false,
      [modalKey]: true,
    });
  };

  const handleCloseModal = () => {
    setModalShow({
      punchModal: false,
      breakModal: false,
      punchOutModal: false,
      returnBreakModal: false,
    });
  };

  const month = dayjs(day || new Date()).format(
    day ? "D MMMM YYYY" : "MMMM YYYY"
  );

  const handleToggleTabsForm = () => {
    setShowTabsForm(!showTabsForm);
  };

  const todayShift = useMemo(() => {
    if (resScheduling?.assignShiftsByDate?.length === 1) {
      return resScheduling?.assignShiftsByDate[0];
    }

    const today = dayjs();
    return resScheduling?.assignShiftsByDate?.find((shift) => {
      // Check if any schedule in shifts array has today's date
      return shift.shifts.some((schedule) => {
        const scheduleDate = dayjs(schedule.date);
        return today.isSame(scheduleDate, "day");
      });
    });
  }, [resScheduling]);
  const shiftOptionList = useMemo(() => {
    return (
      todayShift?.shifts?.map((value) => ({
        label: value.shift?.shiftName,
        value: value.shift?.uId,
      })) || []
    );
  }, [todayShift]);
  const attendanceFormData = useMemo(() => {
    let formData = {};

    todayShift?.shifts?.forEach((shift) => {
      const jobPosition = shift.jobPosition;
      const locationId = shift?.shift?.locationId;

      // Find the location matching the locationId in locationDataApi
      const matchedLocation = (resLocation?.data?.data || [])?.find(
        (location) => location.uId === locationId
      );

      const matchedLocationName = matchedLocation
        ? matchedLocation.locationName
        : "";

      // Update attendanceFormData incrementally
      formData = {
        ...formData,
        [shift.shiftId]: {
          jobPosition: {
            label: jobPosition,
            value: jobPosition,
          },
          location: {
            label: matchedLocationName,
            value: matchedLocationName,
          },
        },
      };
    });

    return formData;
  }, [resLocation?.data?.data, todayShift?.shifts]);

  return (
    <>
      <div className="flex flex-col mt-4">
        <p className="text-lg font-bold">Welcome Back, {currentUserName}</p>
      </div>
      <div className="flex flex-col-reverse w-full gap-4 mt-4 sm:flex-row">
        {/* Left side */}
        <div className="flex flex-col w-4/5 col-span-3 gap-2 max-sm:w-3/4">
          {/* Widget Card Section */}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
            <DataWidget todayShift={todayShift} />

            <PunchWidget
              handleOpenModal={handleOpenModal}
              todayShift={todayShift}
            />

            <BreakWidget handleOpenModal={handleOpenModal} />
          </div>

          {/* Form Modals Section  */}
          <PunchModal
            type={modalShow.punchModal ? "in" : "out"}
            isShow={modalShow.punchModal || modalShow.punchOutModal}
            {...{
              handleCloseModal,
              attendanceFormData,
              shiftOptionList,
            }}
          />

          <BreakModal
            type={modalShow.breakModal ? "break" : "return"}
            isShow={modalShow.breakModal || modalShow.returnBreakModal}
            {...{
              attendanceFormData,
              handleCloseModal,
              shiftOptionList,
            }}
          />

          <ShiftScheduling />
        </div>

        {/* Right side */}
        <div className="flex flex-col mt-3 max-sm:!w-1/4 w-1/5 sm:col-span-1 sm:mt-0">
          <CustomCalendarData />
          {/* <CustomCalendar currentDate={day} setDate={setDateData} /> */}
          <div className="flex items-center justify-between text-container">
            <p className="font-semibold text-label">Whats on in {month} ?</p>

            <div className="flex items-center gap-2 ">
              <ButtonSelectCountry />
              {/* 
              <button
                className="flex-shrink-0 w-10 h-10 text-2xl text-black bg-white border-none"
                onClick={handleToggleTabsForm}
              >
                +
              </button> */}
            </div>
          </div>
          <Tabs
            options={exampleOptions}
            width={95}
            day={day}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onClickList={(id) => {
              dispatch(setCatchUId(id));
              dispatch(setShowModal(true));
            }}
          />
          {showTabsForm && (
            <TestModalDialog
              title="Create New Events"
              onClose={handleToggleTabsForm}
              className={"w-[700px]"}
            >
              <EventForm onClose={handleToggleTabsForm} />
            </TestModalDialog>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(EmployeeDashboardPage);
