import ChevronSVG from "assets/icons/chevron";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const CustomCalendar = ({
  currentDate,
  onClick,
  selectTextSize,
  contentTextSize,
  setDate,
  holidays,
}) => {
  const [currentMonth, setCurrentMonth] = useState(
    new Date(
      dayjs(currentDate).toDate().getFullYear(),
      dayjs(currentDate).toDate().getMonth(),
      1
    )
  );
  const [showPicker, setShowPicker] = useState(false);
  const [clickedDayKey, setClickedDayKey] = useState(currentDate || null);

  useEffect(() => {
    setCurrentMonth(
      new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
    );
  }, [currentDate]);

  const handlePrevMonth = () => {
    setCurrentMonth(
      (prevMonth) =>
        new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      (nextMonth) =>
        new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1)
    );
  };

  const handleChangeMonth = (event) => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), parseInt(event.target.value), 1)
    );
  };

  const handleChangeYear = (event) => {
    setCurrentMonth(
      new Date(parseInt(event.target.value), currentMonth.getMonth(), 1)
    );
  };

  const handleTogglePicker = () => {
    setShowPicker(!showPicker);
  };

  const handleClick = (dayKey) => {
    if (clickedDayKey && dayKey.toString() === clickedDayKey.toString()) {
      setDate(undefined);
      setClickedDayKey(null);
      return;
    }
    setDate(dayKey);
    setClickedDayKey(dayKey);
  };

  const firstDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    0
  );
  const lastDayOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  );
  const daysInMonth = lastDayOfMonth.getDate();
  const startingDay = firstDayOfMonth.getDay();

  // const renderDays = () => {
  //   const days = [];

  //   // Menggeser hari-hari bulan sebelum tanggal 1
  //   for (let i = 0; i < startingDay; i++) {
  //     days.push(<div key={`empty-${i}`} className="empty-day" />);
  //   }

  //   let dayOfMonth = 1;
  //   // Mengelompokkan tanggal ke dalam minggu yang sesuai
  //   const weeks = [];
  //   while (dayOfMonth <= daysInMonth) {
  //     const week = [];
  //     for (let i = 0; i < 7; i++) {
  //       if (dayOfMonth <= daysInMonth) {
  //         const dayKey = dayjs(currentMonth).date(dayOfMonth).toDate();

  //         const unclickedStyles = {
  //           // transform: "translateY(-5%)", // Move the div slightly upward
  //           display: "flex", // Ensure the text is centered inside the div
  //           alignItems: "center", // Vertically center the text
  //           justifyContent: "center", // Horizontally center the text
  //           // transition: 'transform 0.3s ease',
  //         };

  //         const clickedStyles = {
  //           ...unclickedStyles, // Inherit from unclicked styles
  //           backgroundColor: "#dd7224", // Set background color for clicked state
  //           color: "white", // Text color for clicked state
  //         };

  //         week.push(
  //           <div
  //             key={dayKey}
  //             className="cursor-pointer font-semibold calendar-day hover:bg-[#dd7224] hover:text-white rounded-xl aspect-square"
  //             style={
  //               clickedDayKey && clickedDayKey.toString() === dayKey.toString()
  //                 ? clickedStyles
  //                 : unclickedStyles
  //             }
  //             onClick={() => handleClick(dayKey)}
  //           >
  //             {/* This div contains the number itself, which won't be affected by the hover */}
  //             <span style={{ fontSize: "12px" }}>{dayOfMonth}</span>
  //           </div>
  //         );
  //         dayOfMonth++;
  //       }
  //     }
  //     weeks.push(week);
  //   }

  //   // Meratakan struktur minggu menjadi satu dimensi
  //   weeks.forEach((week) => {
  //     days.push(...week);
  //   });

  //   return days;
  // };

  // const renderDays = () => {
  //   const days = [];

  //   // Mengubah string tanggal di holidays menjadi objek Date untuk perbandingan
  //   const holidayDates = holidays.map((h) => new Date(h).toDateString());

  //   // Menggeser hari-hari bulan sebelum tanggal 1
  //   for (let i = 0; i < startingDay; i++) {
  //     days.push(<div key={`empty-${i}`} className="empty-day" />);
  //   }

  //   let dayOfMonth = 1;
  //   const weeks = [];
  //   while (dayOfMonth <= daysInMonth) {
  //     const week = [];
  //     for (let i = 0; i < 7; i++) {
  //       if (dayOfMonth <= daysInMonth) {
  //         const dayKey = dayjs(currentMonth).date(dayOfMonth).toDate();
  //         const isHoliday = holidayDates.includes(dayKey.toDateString());

  //         const baseStyles = {
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         };

  //         const clickedStyles = {
  //           ...baseStyles,
  //           // backgroundColor: "#dd7224",
  //           color: "red",
  //         };

  //         const holidayStyles = {
  //           ...baseStyles,
  //           // backgroundColor: "#ff5e5e", // Warna merah untuk hari libur
  //           color: "red",
  //         };

  //         week.push(
  //           <div
  //             key={dayKey}
  //             className="cursor-pointer font-semibold calendar-day hover:bg-[#dd7224] hover:text-white rounded-xl aspect-square"
  //             style={
  //               clickedDayKey && clickedDayKey.toString() === dayKey.toString()
  //                 ? clickedStyles
  //                 : isHoliday
  //                 ? holidayStyles
  //                 : baseStyles
  //             }
  //             onClick={() => handleClick(dayKey)}
  //           >
  //             <span style={{ fontSize: "12px" }}>{dayOfMonth}</span>
  //           </div>
  //         );
  //         dayOfMonth++;
  //       }
  //     }
  //     weeks.push(week);
  //   }

  //   weeks.forEach((week) => {
  //     days.push(...week);
  //   });

  //   return days;
  // };

  const renderDays = () => {
    const days = [];

    // Ambil hanya tanggal yang sesuai dengan currentMonth
    const holidayDates = holidays
      .map((h) => new Date(h))
      .filter(
        (date) =>
          date.getMonth() === currentMonth.getMonth() &&
          date.getFullYear() === currentMonth.getFullYear()
      )
      .map((date) => date.toDateString()); // Ubah ke format yang bisa dibandingkan

    // Geser hari-hari sebelum tanggal 1
    for (let i = 0; i < startingDay; i++) {
      days.push(<div key={`empty-${i}`} className="empty-day" />);
    }

    let dayOfMonth = 1;
    const weeks = [];
    while (dayOfMonth <= daysInMonth) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        if (dayOfMonth <= daysInMonth) {
          const dayKey = dayjs(currentMonth).date(dayOfMonth).toDate();
          const isHoliday = holidayDates.includes(dayKey.toDateString());

          const baseStyles = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          };

          const clickedStyles = {
            ...baseStyles,
            backgroundColor: "#dd7224",
            color: "white",
          };

          const holidayStyles = {
            ...baseStyles,
            // backgroundColor: "#ff5e5e", 
            color: "red",
          };

          week.push(
            <div
              key={dayKey}
              className="cursor-pointer font-semibold calendar-day hover:bg-[#dd7224] hover:text-white rounded-xl aspect-square"
              style={
                clickedDayKey && clickedDayKey.toString() === dayKey.toString()
                  ? clickedStyles
                  : isHoliday
                  ? holidayStyles
                  : baseStyles
              }
              onClick={() => handleClick(dayKey)}
            >
              <span style={{ fontSize: "12px" }}>{dayOfMonth}</span>
            </div>
          );
          dayOfMonth++;
        }
      }
      weeks.push(week);
    }

    weeks.forEach((week) => {
      days.push(...week);
    });

    return days;
  };

  const renderWeeks = () => {
    const weeks = [];

    let dayOfMonth = 1;
    const numberOfWeeks = Math.ceil((daysInMonth + startingDay) / 7);

    // Menambahkan label hari-hari di bagian atas kalender
    weeks.push(
      <div
        key="week-labels"
        className={`grid grid-cols-8 gap-2 items-center text-${contentTextSize} font-lato text-center`}
      >
        <div className="text-orange-400 week-label">Week</div>
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
          <div key={day} className="font-semibold">
            {day}
          </div>
        ))}
      </div>
    );

    for (let i = 0; i < numberOfWeeks; i++) {
      const weekNumber = getWeekNumber(
        new Date(
          currentMonth.getFullYear(),
          currentMonth.getMonth(),
          dayOfMonth
        )
      );
      weeks.push(
        <div
          key={`week-${i}`}
          className={`grid grid-cols-8 text-${contentTextSize} font-lato text-center`}
        >
          <div className="flex items-center justify-center text-orange-400 week-label">
            {weekNumber}
          </div>
          {renderDays().splice(i * 7, 7)}
        </div>
      );
      dayOfMonth += 7;
    }

    return weeks;
  };

  // Fungsi untuk menghitung minggu dalam tahun
  const getWeekNumber = (date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const weekNum = Math.ceil(
      ((date - onejan) / 86400000 + onejan.getDay() + 1) / 7
    );
    return weekNum;
  };

  // Fungsi untuk memeriksa apakah dua tanggal sama
  const isSameDay = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  return (
    <div className="w-full flex flex-col bg-[#ffd9be] p-4 rounded-md shadow-md cursor-pointer">
      <div
        className={`flex flex-row items-center  justify-between text-${selectTextSize} font-lato font-medium gap-2`}
      >
        <ChevronSVG
          direction="left"
          className="w-4 h-4"
          onClick={handlePrevMonth}
        />
        {showPicker ? (
          <div className="flex gap-2 text-sm font-semibold">
            <select
              className="px-2 py-1 text-black bg-orange-100 border border-black rounded-md shadow-sm focus:outline-none focus:border-orange-500"
              value={currentMonth.getMonth()}
              onChange={handleChangeMonth}
            >
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i} value={i}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
            <select
              className="px-2 py-1 text-black bg-orange-100 border border-black rounded-md shadow-sm focus:outline-none focus:border-orange-500"
              value={currentMonth.getFullYear()}
              onChange={handleChangeYear}
            >
              {Array.from({ length: 100 }, (_, i) => {
                const year = new Date().getFullYear() - 50 + i; // Adjust based on your needs
                return (
                  <option key={i} value={year}>
                    {year}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <div
            className="flex text-sm font-semibold"
            onClick={handleTogglePicker}
          >
            <div>
              {currentMonth.toLocaleString("default", { month: "long" })}{" "}
              {currentMonth.getFullYear()}
            </div>
          </div>
        )}
        <ChevronSVG
          direction="right"
          className="w-4 h-4"
          onClick={handleNextMonth}
        />
      </div>
      <hr className="my-4 border-orange-400" />
      {renderWeeks()}
    </div>
  );
};

CustomCalendar.propTypes = {
  currentDate: PropTypes.instanceOf(Date),
  onClick: PropTypes.func,
  selectTextSize: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  contentTextSize: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

CustomCalendar.defaultProps = {
  currentDate: new Date(),
  onClick: (dayKey) => console.log("Clicked on day:", dayKey),
  selectTextSize: "sm",
  contentTextSize: "xs",
};

export default CustomCalendar;
