import React from "react";
import CustomCalendar from "components/calendar";
import { useDivRef } from "utils/context";
import { useSelector } from "react-redux";

import { useGetAllHoliday } from "services/employeeAPI";

function CustomCalendarData() {
  const { dateData, setDateData } = useDivRef();
  const { eventCountry } = useSelector((state) => state.event);

  const { data: dataHoliday, isLoading: loadingHoliday } = useGetAllHoliday({
    date: "",
    country: eventCountry.name,
  });

  if (loadingHoliday) {
    return null;
  }

  // GENERATE ALL HOLIDAY
  const allHoliday =
    dataHoliday.data.data &&
    dataHoliday.data.data.map((item) => {
      return item.startDate;
    });


  return (
    <div>
      <CustomCalendar
        currentDate={dateData}
        setDate={setDateData}
        holidays={allHoliday}
      />
    </div>
  );
}

export default CustomCalendarData;

// console.log("DATA HOLIDAY: ", allHoliday);

// const renderDays = () => {
//   const days = [];

//   // Menggeser hari-hari bulan sebelum tanggal 1
//   for (let i = 0; i < startingDay; i++) {
//     days.push(<div key={`empty-${i}`} className="empty-day" />);
//   }

//   let dayOfMonth = 1;
//   const weeks = [];
//   while (dayOfMonth <= daysInMonth) {
//     const week = [];
//     for (let i = 0; i < 7; i++) {
//       if (dayOfMonth <= daysInMonth) {
//         const dayKey = dayjs(currentMonth).date(dayOfMonth).toDate();

//         const isHoliday = holidays.some((holiday) =>
//           isSameDay(dayKey, holiday)
//         ); // Cek apakah hari ini adalah hari libur

//         const unclickedStyles = {
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         };

//         const clickedStyles = {
//           ...unclickedStyles,
//           backgroundColor: "#dd7224",
//           color: "white",
//         };

//         const holidayStyles = {
//           ...unclickedStyles,
//           backgroundColor: "#ffcccb", // Gaya untuk hari libur
//           color: "red", // Warna teks untuk hari libur
//         };

//         week.push(
//           <div
//             key={dayKey}
//             className="cursor-pointer font-semibold calendar-day hover:bg-[#dd7224] hover:text-white rounded-xl aspect-square"
//             style={
//               clickedDayKey && clickedDayKey.toString() === dayKey.toString()
//                 ? clickedStyles
//                 : isHoliday
//                 ? holidayStyles
//                 : unclickedStyles
//             }
//             onClick={() => handleClick(dayKey)}
//           >
//             <span style={{ fontSize: "12px" }}>{dayOfMonth}</span>
//           </div>
//         );
//         dayOfMonth++;
//       }
//     }
//     weeks.push(week);
//   }

//   weeks.forEach((week) => {
//     days.push(...week);
//   });

//   return days;
// };

// const renderDays2 = () => {
//   const days = [];

//   // Menggeser hari-hari bulan sebelum tanggal 1
//   for (let i = 0; i < startingDay; i++) {
//     days.push(<div key={`empty-${i}`} className="empty-day" />);
//   }

//   let dayOfMonth = 1;
//   const weeks = [];
//   while (dayOfMonth <= daysInMonth) {
//     const week = [];
//     for (let i = 0; i < 7; i++) {
//       if (dayOfMonth <= daysInMonth) {
//         const dayKey = dayjs(currentMonth).date(dayOfMonth).toDate();

//         // Cek apakah hari ini adalah hari libur
//         const isHoliday = holidays.some((holiday) => {
//           const startDate = new Date(holiday.startDate);
//           const endDate = new Date(holiday.endDate);
//           return dayKey >= startDate && dayKey <= endDate; // Cek apakah dayKey berada dalam rentang
//         });

//         const unclickedStyles = {
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         };

//         const clickedStyles = {
//           ...unclickedStyles,
//           backgroundColor: "#dd7224",
//           color: "white",
//         };

//         const holidayStyles = {
//           ...unclickedStyles,
//           backgroundColor: "#ffcccb", // Gaya untuk hari libur
//           color: "red", // Warna teks untuk hari libur
//         };

//         week.push(
//           <div
//             key={dayKey}
//             className="cursor-pointer font-semibold calendar-day hover:bg-[#dd7224] hover:text-white rounded-xl aspect-square"
//             style={
//               clickedDayKey && clickedDayKey.toString() === dayKey.toString()
//                 ? clickedStyles
//                 : isHoliday
//                 ? holidayStyles
//                 : unclickedStyles
//             }
//             onClick={() => handleClick(dayKey)}
//           >
//             <span style={{ fontSize: "12px" }}>{dayOfMonth}</span>
//           </div>
//         );
//         dayOfMonth++;
//       }
//     }
//     weeks.push(week);
//   }

//   weeks.forEach((week) => {
//     days.push(...week);
//   });

//   return days;
// };
