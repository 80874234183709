import React from "react";
import { ConflictIcon, ReplacementSvg } from "../../Icon";

function AssignShiftData({
  assignShift,
  handleEditAssignShift,
  employeeData,
  allShift,
  handleConflickAssign,
  isAnyConflick,
  roleUser,
  selectView,
}) {
  const shiftDetails = assignShift?.shiftDetails;

  const combineShiftAndFilterData = {
    allShift: allShift,
    assignShift: assignShift,
    employee: employeeData,
  };

  const employee = assignShift?.employeeData;

//   ${
//     !roleUser && !assignShift.isActive && "hidden"
//   }  ${!assignShift?.isActive && "opacity-60"}

  return (
    // ASSIGN SHIFT
    <>
      {isAnyConflick === true && assignShift.conflickData.length > 0 ? (
        <div className="relative cursor-pointer h-[56px] ">
          <div
            style={{
              backgroundColor: shiftDetails?.color,
            }}
            onClick={() =>
              handleEditAssignShift(combineShiftAndFilterData, assignShift?.uId)
            }
            className={`flex flex-col justify-center items-center h-[56px] w-full cursor-pointer rounded-md text-[13px]  ${
              !assignShift.isActive && "opacity-60"
            }`}
          >
            {selectView === "Employee View" && (
              <>
                <h1>{shiftDetails?.shiftName} </h1>
              </>
            )}

            {selectView === "Job Position View" && (
              <>
                <h1>{employee?.firstName} </h1>
              </>
            )}

            <p>
              {shiftDetails?.startTime} - {shiftDetails?.endTime}
            </p>
          </div>

          {assignShift.isReplacement ? (
            <div className="absolute top-[-5px] right-[-5px]">
              <ReplacementSvg />
            </div>
          ) : (
            <div
              className="absolute top-[-5px] right-[-5px] text-[13px]"
              onClick={() => handleConflickAssign(assignShift)}
            >
              <ConflictIcon />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: shiftDetails?.color,
          }}
          className={`flex flex-col justify-center items-center h-[56px] w-full cursor-pointer rounded-md relative text-[14px]`}
        //   onClick={() =>
        //     handleEditAssignShift(combineShiftAndFilterData, assignShift.uId)
        //   }
        >
          {selectView === "Employee View" && (
            <>
              <h1>{shiftDetails?.shiftName} </h1>
            </>
          )}

          {selectView === "Job Position View" && (
            <>
              <h1>{employee?.firstName} </h1>
            </>
          )}

          <p>
            {shiftDetails?.startTime} - {shiftDetails?.endTime}
          </p>

          {assignShift?.isReplacement && (
            <div className="absolute top-[-5px] right-[-5px]">
              <ReplacementSvg />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AssignShiftData;
