import { Skeleton } from "@bluesilodev/timhutcomponents";
import dayjs from "dayjs";
import { useCallback } from "react";

import DisablePunchIcon from "assets/icon/DisablePunchSVG";
import PunchOutIcon from "assets/icon/PunchOutSVG";
import PunchIcon from "assets/icon/PunchSVG";
import { Stopwatch } from "pages/user/widgets";
import { useGetAttendanceByUser } from "services/attendanceAPI";
import { useGetByIdShift } from "services/schedulingAPI";
import { cn } from "utils/common";

const PunchWidget = ({ handleOpenModal, todayShift }) => {
  const { data: userAttendance, isLoading } = useGetAttendanceByUser();
  const { data: userShift, isLoadingShift } = useGetByIdShift({
    uId:
      userAttendance?.data?.currentData?.scheduleID ??
      userAttendance?.data?.todayData?.scheduleID,
  });

  const isNeedToPunchOut =
    userAttendance?.data?.currentData?.punchIn &&
    !userAttendance?.data?.currentData?.punchOut;
  const type = isNeedToPunchOut ? "out" : "in";
  const modalKey = type === "in" ? "punchModal" : "punchOutModal";

  const getDisabledState = useCallback(() => {
    const startTime =
      userShift?.data?.data?.startTime?.split(":") ||
      todayShift?.shifts?.[0]?.shift?.startTime?.split(":") ||
      [];
    const endTime =
      userShift?.data?.data?.endTime?.split(":") ||
      todayShift?.shifts?.[0]?.shift?.endTime?.split(":") ||
      [];

    if (
      (!isNeedToPunchOut && startTime?.length < 2) ||
      (isNeedToPunchOut && endTime?.length < 2)
    )
      return true;

    const [hourStartTime, minuteStartTime] = startTime;
    const [hourEndTime, minuteEndTime] = endTime;
    const allowedPunchInTime = dayjs()
      .hour(hourStartTime)
      .minute(minuteStartTime)
      .second(0);
    const allowedPunchOutTime = dayjs(
      userAttendance?.data?.currentData?.punchIn
    )
      .hour(hourEndTime)
      .minute(minuteEndTime)
      .second(0);
    const now = dayjs();
    const currentBreak = userAttendance?.data?.currentData?.breaks?.at(-1);

    /**
     * This condition will make the button punch in or punch out to be disabled :
     * 1. If user want punch in but the time is before the allowed punch in time
     * 2. If user want punch in but the time is after the punch out time
     * 3. If user want punch out but the time is before the allowed punch out time
     * 4. If user want punch out but still in break time
     */
    if (
      (!isNeedToPunchOut && now.isBefore(allowedPunchInTime)) ||
      (!isNeedToPunchOut && now.isAfter(allowedPunchOutTime)) ||
      (isNeedToPunchOut && now.isBefore(allowedPunchOutTime)) ||
      (isNeedToPunchOut &&
        currentBreak?.breakTime &&
        !currentBreak?.returnFromBreak)
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    isNeedToPunchOut,
    todayShift?.shifts,
    userAttendance?.data?.currentData?.breaks,
    userAttendance?.data?.currentData?.punchIn,
    userShift?.data?.data?.endTime,
    userShift?.data?.data?.startTime,
  ]);

  const disabled = getDisabledState();

  // Determine title based on type
  const title = isNeedToPunchOut ? "Punch-out to shift" : "Punch-in to shift";

  // Format time values
  const punchInTime = isNeedToPunchOut
    ? dayjs(userAttendance?.data?.currentData?.punchIn).format(
        "DD MMMM YYYY HH:mm"
      )
    : "No Punch In Time";

  // Select the appropriate icon based on type and disabled state
  const renderIcon = () => {
    if (disabled) return <DisablePunchIcon />;
    return type === "in" ? <PunchIcon /> : <PunchOutIcon />;
  };

  const handleClick = useCallback(() => {
    if (disabled) return;
    handleOpenModal(modalKey);
  }, [disabled, handleOpenModal, modalKey]);

  if (isLoading || isLoadingShift) {
    return (
      <div className="flex items-center justify-between gap-2 border-2 border-gray-300 rounded-lg shadow-xl">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }

  return (
    <div
      className={cn(
        "rounded-lg shadow-xl flex items-center justify-between border-gray-300 border-2 p-2 gap-2 cursor-pointer",
        disabled && "relative cursor-not-allowed"
      )}
      onClick={handleClick}
    >
      <div className="flex flex-col gap-2 left-container">
        <p className="text-xs font-bold">{title}</p>
        <p className="text-xs">{punchInTime}</p>
        <Stopwatch
          startFrom={userAttendance?.data?.currentData?.punchIn || null}
          isTimerRunning={isNeedToPunchOut}
        />
        <p className="text-xs">{userShift?.data?.data?.shiftName}</p>
      </div>
      <div className="mr-8 right-container">{renderIcon()}</div>
      {disabled && (
        <div className="absolute inset-0 bg-gray-100 rounded-lg opacity-50"></div>
      )}
    </div>
  );
};

export default PunchWidget;
