import React, { useContext, createContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { generateDateRange } from "utils/utils";
import { useFormik } from "formik";
import { formatDate } from "utils/utils";

const RefContext = createContext();

export const useDivRef = () => useContext(RefContext);

function RefProvider({ children }) {
  const [remainingHeight, setRemainingHeight] = useState(0);
  const [dateData, setDateData] = useState(undefined);

  const today = dayjs().toDate();
  const nextWeek = dayjs().add(7, "day").toDate();

  const [dateRange, setDateRange] = useState({});
  const [dateValue, setDateValue] = useState(
    generateDateRange(today, nextWeek)
  );
  const { values, setFieldValue } = useFormik({
    initialValues: {
      date: dateValue, // Set initial date value
    },
  });

  useEffect(() => {
    setDateValue(values.date);
  }, [values.date]);

  useEffect(() => {
    setFieldValue("date", dateValue);
  }, [setFieldValue, dateValue]);

  useEffect(() => {
    if (Array.isArray(dateValue) && dateValue.length > 0) {
      setDateRange({
        fromDate: formatDate(dateValue[0]), // First date in the range
        toDate: formatDate(dateValue[dateValue.length - 1]), // Last date in the range
      });
    }
  }, [dateValue]);

  useEffect(() => {
    const handleResize = () => {
      const totalHeight = 55 + 60 + 260 + 60;

      // Set height thay left
      setRemainingHeight(window.innerHeight - totalHeight);
    };

    // count height when firstime render
    handleResize();

    // Add event listener for changes screen size
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when komponen unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const contextVal = {
    remainingHeight,
    dateData,
    setDateData,

    // DATE RANGE PER WEEK
    // dateValue, setDateValue, setFieldValue,
    dateValue,
    setDateValue,
    setFieldValue,
    dateRange,
    setDateRange,
  };

  return (
    <RefContext.Provider value={contextVal}>{children}</RefContext.Provider>
  );
}

export default RefProvider;
