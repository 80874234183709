import { CountrySelectV2 } from "@bluesilodev/timhutcomponents";
import { useDispatch, useSelector } from "react-redux";
import { setEventCountry } from "store/reducer/event";

export const ButtonSelectCountry = () => {
  const dispatch = useDispatch();

  const { eventCountry } = useSelector((state) => state.event);

  return (
    <CountrySelectV2
      heightDiv={30}
      classname={"w-[90px] h-[30px] flex justify-center item-center mt-4"}
      heigthFlag={20}
      value={eventCountry.iso2}
      widthFlag={20}
      onChange={(val) => {
        dispatch(setEventCountry(val));
      }}
    />
  );
};
