import { Skeleton } from "@bluesilodev/timhutcomponents";
import dayjs from "dayjs";
import { memo, useCallback } from "react";

import BreakIcon from "assets/icon/BreakSVG";
import GoBreakIcon from "assets/icon/GoBreakSVG";
import ReturnBreakIcon from "assets/icon/ReturnBreakSVG";
import { Stopwatch } from "pages/user/widgets";
import { useGetAttendanceByUser } from "services/attendanceAPI";
import { useGetByIdShift } from "services/schedulingAPI";
import { cn } from "utils/common";

const BreakWidget = ({ handleOpenModal }) => {
  const { data: userAttendance, isLoading } = useGetAttendanceByUser();
  const { data: userShift, isLoadingShift } = useGetByIdShift({
    uId: userAttendance?.data?.currentData?.scheduleID,
  });

  const currentBreak =
    userAttendance?.data?.currentData?.breaks?.at(-1) || null;
  const isNeedToReturnFromBreak = currentBreak && !currentBreak.returnFromBreak;
  const type = isNeedToReturnFromBreak ? "return" : "break";
  const modalKey = type === "break" ? "breakModal" : "returnBreakModal";

  const getDisabledState = () => {
    const startTime = userShift?.data?.data?.startBreakTime?.split(":") || [];
    const endTime = userShift?.data?.data?.endBreakTime?.split(":") || [];
    if (startTime?.length < 2 || endTime?.length) return true;

    const [hourStartTime, minuteStartTime] = startTime;
    const [hourEndTime, minuteEndTime] = endTime;
    const allowedBreakTime = dayjs(userAttendance?.data?.currentData?.punchIn)
      .hour(hourStartTime)
      .minute(minuteStartTime)
      .second(0);
    const allowedReturnTime = dayjs(userAttendance?.data?.currentData?.punchIn)
      .hour(hourEndTime)
      .minute(minuteEndTime)
      .second(0);
    const now = dayjs();

    /**
     * This condition will make the button break orreturn from break to be disabled :
     * 1. If user want break but the time is before the allowed start break time
     * 2. If user want break but the time is after the allowed end break time
     * 3. If user want break but no active current attendance
     */
    if (
      (!isNeedToReturnFromBreak && now.isBefore(allowedBreakTime)) ||
      (!isNeedToReturnFromBreak && now.isAfter(allowedReturnTime))(
        !isNeedToReturnFromBreak && !userAttendance?.data?.currentData
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const disabled = getDisabledState();

  // Determine title based on type
  const title = type === "return" ? "Return From Break" : "Go For Break";

  // Format time values
  const breakTime =
    isNeedToReturnFromBreak && currentBreak?.breakTime
      ? dayjs(currentBreak.breakTime).format("DD MMMM YYYY HH:mm")
      : "No Break Time";

  // Determine icon based on type
  const renderIcon = () => {
    if (disabled) return <BreakIcon />;
    return type === "return" ? <ReturnBreakIcon /> : <GoBreakIcon />;
  };

  const handleClick = useCallback(() => {
    if (disabled) return;
    handleOpenModal(modalKey);
  }, [disabled, handleOpenModal, modalKey]);

  if (isLoading || isLoadingShift) {
    return (
      <div className="flex gap-2 justify-between items-center rounded-lg border-2 border-gray-300 shadow-xl">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }

  return (
    <div
      className={cn(
        "rounded-lg shadow-xl border-gray-300 border-2 relative flex items-center justify-between p-2 gap-2 cursor-pointer",
        disabled && "relative cursor-not-allowed"
      )}
      onClick={handleClick}
    >
      <div className="flex flex-col gap-2 left-container">
        <p className="text-xs font-bold">{title}</p>
        <p className="text-xs">{breakTime}</p>
        <Stopwatch
          startFrom={currentBreak?.breakTime}
          isTimerRunning={isNeedToReturnFromBreak}
        />
        <p className="text-xs">{userShift?.data?.data?.shiftName}</p>
      </div>
      <div className="mr-8 right-container">{renderIcon()}</div>
      {disabled && (
        <div className="absolute inset-0 bg-gray-100 rounded-lg opacity-50"></div>
      )}
    </div>
  );
};

export default memo(BreakWidget);
