import PunchInForm from "components/modal/punchInModal";
import PunchOutForm from "components/modal/punchOutModal";
import TestModalDialog from "components/testModalDialog";
import { useEffect } from "react";
import { createPortal } from "react-dom";

const PunchModal = ({
  type = "in",
  handleCloseModal,
  shiftOptionList,
  attendanceFormData,
  isShow,
}) => {
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isShow]);

  return (
    !!isShow &&
    createPortal(
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <TestModalDialog
          title={type === "in" ? "Punch In" : "Punch Out"}
          onClose={handleCloseModal}
          className="w-[700px]"
        >
          {type === "in" ? (
            <PunchInForm
              onClose={handleCloseModal}
              shiftOptionList={shiftOptionList}
              attendanceFormData={attendanceFormData}
            />
          ) : (
            <PunchOutForm
              onClose={handleCloseModal}
              shiftOptionList={shiftOptionList}
              attendanceFormData={attendanceFormData}
            />
          )}
        </TestModalDialog>
      </div>,
      document.body
    )
  );
};

export default PunchModal;
