import {
  CheckBox,
  InputDate,
  InputSelect,
  InputText,
  ModalDialog,
  UploadImg1,
  Button,
} from "@bluesilodev/timhutcomponents";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import {
  useGetOneEvent,
  useGetLocationQuery,
  handleUpdateEvent,
  handleDeleteEvent,
} from "services/employeeAPI";

import { setShowModal } from "store/reducer/event";
import { formatDateSlash } from "utils/dateAndTime/generatedTime";
import { editEventSchema } from "./eventSchema";
import { WidgetSvg, FiTrash2 } from "assets/icons";
import { alertError, alertSuccess } from "utils/alert";
import { formatDateStrip } from "utils/utils";

function ModalEvent() {
  const { catchUId } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: dataEventByID, isLoading: loadingGetOneEvent } =
    useGetOneEvent(catchUId);

  const { data: dataLocation, isLoading: loadingLocation } =
    useGetLocationQuery();

  if (loadingGetOneEvent || loadingLocation) {
    return <div>Loading...</div>;
  }

  const listLocation =
    dataLocation.data.data.length > 0 &&
    dataLocation.data.data.map((item) => {
      return {
        label: item.locationName,
        value: item.uId,
      };
    });

  const dataOneEvent = catchUId && dataEventByID && dataEventByID?.data;

  const initialValue = {
    nameEvent: dataOneEvent.nameEvent || "",
    startDate: formatDateSlash(dataOneEvent.startDate) || "",
    endDate: formatDateSlash(dataOneEvent.endDate) || "",
    location: dataOneEvent.location || "",
    duration: dataOneEvent.duration || "",
    posterAttachment: dataOneEvent.posterAttachment || null,
    // description: "",
    isCompanyHoliday: dataOneEvent?.isCompanyHoliday || false,
    note: dataOneEvent.note || "",
  };

  return (
    <ModalDialog
      title={"Events Details"}
      className={"w-[600px] h-[600px]"}
      onClose={() => {
        dispatch(setShowModal(false));
      }}
      icon={[
        {
          icon: (
            <div
              className="mr-4 "
              onClick={() => {
                handleDeleteEvent({
                  id: catchUId,
                  onSuccess: () => {
                    queryClient.invalidateQueries("getEvent");
                    alertSuccess("Delete Event Successfully");
                    dispatch(setShowModal(false));
                  },
                  onError: () => {
                    alertError("Delete Event Failed");
                  },
                });
              }}
            >
              <FiTrash2 />
            </div>
          ),
        },
      ]}
      children={
        <>
          <Formik
            initialValues={initialValue}
            validationSchema={editEventSchema}
            onSubmit={(val, { resetForm }) => {
              const allData = {
                ...val,
                startDate: formatDateStrip(val.startDate),
                endDate: formatDateStrip(val.endDate),
              };

              const form = new FormData();
              form.append("nameEvent", allData.nameEvent);
              form.append("startDate", allData.startDate);
              form.append("endDate", allData.endDate);
              form.append("isCompanyHoliday", allData.isCompanyHoliday);
              form.append("posterAttachment", allData.posterAttachment);
              form.append("duration", allData.duration);
              form.append("note", allData.note);
              form.append("location", allData.location);

              handleUpdateEvent({
                id: catchUId,
                val: form,
                onSuccess: () => {
                  queryClient.invalidateQueries("getEvent");
                  alertSuccess("Update Event Successfully");
                  dispatch(setShowModal(false));
                },
                onError: () => {
                  alertError("Update Event Failed");
                },
              });
            }}
            className="w-full px-1 py-3 mt-8 overflow-y-auto"
          >
            {({
              handleBlur,
              errors,
              touched,
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => {
              // CALCULATION DATE
              const [startDay, startMonth, startYear] =
                values.startDate.split("/");
              const [endDay, endMonth, endYear] = values.endDate.split("/");

              const start = new Date(startYear, startMonth - 1, startDay);
              const end = new Date(endYear, endMonth - 1, endDay);

              const timeDiff = end.getTime() - start.getTime();

              if (timeDiff) {
                values.duration = timeDiff / (1000 * 3600 * 24);
              }

              // Function for change file
              const changeFile = (e) => {
                const file = e.currentTarget.files[0];

                if (file) {
                  setFieldValue("posterAttachment", file);
                }
              };

              return (
                <>
                  <div className="flex flex-col w-full gap-3 py-5 ">
                    <div className="flex w-full gap-3">
                      <InputText
                        id="nameEvent"
                        title={"Name of Events"}
                        placeholder="Enter Name of Events"
                        value={values.nameEvent}
                        required={true}
                        label={null}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // disabled={true}
                        error={
                          errors.nameEvent &&
                          touched.nameEvent &&
                          errors.nameEvent
                        }
                      />
                      <InputSelect
                        title={"Location"}
                        options={listLocation}
                        value={values.location}
                        required={true}
                        id="location"
                        // disabled={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.location && touched.location && errors.location
                        }
                      />
                    </div>

                    <div className="flex w-full gap-3">
                      <InputDate
                        name={"startDate"}
                        label={"Start Date"}
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        // disabled={true}
                        errors={
                          errors.startDate &&
                          touched.startDate &&
                          errors.startDate
                        }
                        required={true}
                      />

                      <InputDate
                        name={"endDate"}
                        label={"End Date"}
                        value={values.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        // disabled={true}
                        errors={
                          errors.endDate && touched.endDate && errors.endDate
                        }
                        required={true}
                      />
                    </div>

                    <div className="flex w-full gap-3 pt-3">
                      <input
                        id="duration"
                        disabled={true}
                        // onChange={handleDuration}
                        value={values.duration + " Day(s)"}
                        className="bg-gray-200 w-full py-1 pl-4 rounded-md h-[60px] flex items-center  "
                      />

                      <div className="flex items-center w-full gap-3">
                        <div>
                          <CheckBox
                            name={"isCompanyHoliday"}
                            onChange={(v) =>
                              setFieldValue("isCompanyHoliday", v)
                            }
                            value={values.isCompanyHoliday}
                            // disabled={true}
                          />
                        </div>
                        <p>Is This Company Holiday ? </p>
                      </div>
                    </div>

                    <div className="my-4">
                      <UploadImg1
                        onChange={changeFile}
                        textSizeLimit={
                          "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
                        }
                        title={"Attachment"}
                        onBlur={handleBlur}
                        id="posterAttachment"
                        accept="image/jpeg, image/jpg, image/png"
                        disable={true}
                        message={
                          errors.posterAttachment &&
                          touched.posterAttachment ? (
                            <div className="text-red-500">
                              {errors.posterAttachment}
                            </div>
                          ) : (
                            (values?.posterAttachment?.[0]?.name ??
                              values?.posterAttachment?.name) ||
                            ""
                          )
                        }
                      />
                    </div>
                    <div>
                      <textarea
                        id="note"
                        onChange={handleChange}
                        value={values.note}
                        onBlur={handleBlur}
                        // disabled={true}
                        placeholder="Notes"
                        className="w-full border-[1px] border-black resize-none h-[100px] rounded-md outline-none p-3"
                      ></textarea>
                    </div>

                    <Button
                      style="solid"
                      className={"w-full"}
                      onClick={handleSubmit}
                      type={"submit"}
                      label={
                        <div className="flex gap-2">
                          {/* <FiSaveSvg /> */}
                          <WidgetSvg color={"white"} />
                          <div>Save</div>
                        </div>
                      }
                    />
                  </div>
                </>
              );
            }}
          </Formik>
        </>
      }
    ></ModalDialog>
  );
}

export default ModalEvent;
