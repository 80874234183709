import { Skeleton } from "@bluesilodev/timhutcomponents";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useGetAttendanceByUser } from "services/attendanceAPI";
import { useGetByIdShift } from "services/schedulingAPI";
import { cn } from "utils/common";
import { addSpaceToPascalCase } from "utils/utils";

const DataWidget = ({ todayShift }) => {
  const { data: userAttendance, isLoading } = useGetAttendanceByUser();
  const { data: userShift, isLoadingShift } = useGetByIdShift({
    uId: userAttendance?.data?.currentData?.scheduleID,
  });

  const currentAttendance = userAttendance?.data?.currentData;
  const todayAttendance = userAttendance?.data?.todayData;

  // Destructure attendance data with default values if not available
  const attendance = useMemo(() => {
    const data = currentAttendance || todayAttendance;
    const currentBreak = userAttendance?.data?.currentData?.breaks?.at(-1);
    return {
      name:
        userShift?.data?.data?.shiftName ||
        todayShift?.shifts?.[0]?.shift?.shiftName,
      punchInTime: data?.punchIn
        ? dayjs(data?.punchIn).format("DD MMMM YYYY HH:mm")
        : "-",
      punchOutTime: data?.punchOut
        ? dayjs(data?.punchOut).format("DD MMMM YYYY HH:mm")
        : "-",
      breakTime: currentBreak?.breakTime
        ? dayjs(currentBreak?.breakTime).format("DD MMMM YYYY HH:mm")
        : "-",
      returnBreakTime: currentBreak?.returnFromBreak
        ? dayjs(currentBreak?.returnFromBreak).format("DD MMMM YYYY HH:mm")
        : "-",
      status: data?.status,
    };
  }, [
    currentAttendance,
    todayAttendance,
    todayShift?.shifts,
    userAttendance?.data?.currentData?.breaks,
    userShift?.data?.data?.shiftName,
  ]);

  if (isLoading || isLoadingShift) {
    return (
      <div className="flex items-center justify-between gap-2 border-2 border-gray-300 rounded-lg shadow-xl">
        <Skeleton className="w-full h-full" />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center p-2 border-2 border-gray-300 rounded-lg shadow-xl">
      <div className="flex flex-col gap-1">
        <p className="text-xs font-bold">
          {attendance.name
            ? `You currently have a shift : ${attendance.name}`
            : "You currently don't bave shift for today"}
        </p>
        <p className="text-xs">Punch In Time : {attendance.punchInTime}</p>{" "}
        {/* Default to 'N/A' if empty */}
        <p className="text-xs">Break : {attendance.breakTime}</p>
        <p className="text-xs">Resume : {attendance.returnBreakTime}</p>
        <div className="relative flex flex-wrap items-start justify-between">
          <p className="text-xs">Punch Out Time : {attendance.punchOutTime}</p>
          {!!attendance.status && (
            <p
              className={cn(
                "px-4 py-1 relative -top-2 mr-4 text-xs bg-green-300 bg-opacity-90 rounded-xl",
                attendance.status === "Late" && "bg-red-300"
              )}
            >
              {addSpaceToPascalCase(attendance.status)}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataWidget;
