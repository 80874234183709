import ListInputSelect from "components/listInputSelect";
import ShiftListBox from "components/ShiftListBox/ShiftListBox";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetAppQuery } from "services/commonAPI";
import { useGetLocationQuery } from "services/employeeAPI";
import {
  useGetDashboardQuery,
  useGetEmployeeShiftQuery,
  useGetShiftQuery,
} from "services/schedulingAPI";
import { useDivRef } from "utils/context";
import { generateDateRange, handleChangeApp, formatDate } from "utils/utils";

const today = dayjs().day(1).toDate();
const nextWeek = dayjs().day(1).add(6, "d").toDate();

function ShiftScheduling() {
  const [viewSelectValue, setViewSelectValue] = useState("Employee View");
  // const [dateRange, setDateRange] = useState({});
  const [keywordName, setKeywordName] = useState("");
  const [locationSelectValue, setLocationSelectValue] = useState("");
  // const [dateValue, setDateValue] = useState(
  //   generateDateRange(today, nextWeek)
  // );

  const { dateRange, dateValue } = useDivRef();

  // const { values, setFieldValue } = useFormik({
  //   initialValues: {
  //     date: dateValue,
  //   },
  // });

  const { remainingHeight } = useDivRef();

  const { data: apps } = useGetAppQuery();
  const { data: employeesShift } = useGetEmployeeShiftQuery({
    firstName: keywordName,
    startDate: dateRange?.fromDate,
    endDate: dateRange?.toDate,
    locationID: locationSelectValue,
  });
  const { data: allShift } = useGetShiftQuery();
  const { data: locations } = useGetLocationQuery();

  const { data: dashboard, isSuccess: successDashboard } = useGetDashboardQuery(
    {
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
    }
  );

  // console.log("DATE RANGE : ", dateRange);

  const dataEmployeesShift = useMemo(
    () =>
      employeesShift?.data?.data?.map((item) => {
        return { ...item, employee: item.employee || [] };
      }) || [],
    [employeesShift]
  );

  const handleClickSeeMore = useCallback(() => {
    const schedulingApp = apps?.find(
      (link) => link.name === "Scheduling"
    )?.link;

    if (schedulingApp) {
      handleChangeApp(schedulingApp);
    }
  }, [apps]);

  // useEffect(() => {
  //   setDateValue(values.date);
  // }, [values.date]);

  // useEffect(() => {
  //   setFieldValue("date", dateValue);
  // }, [dateValue, setFieldValue]);

  // useEffect(() => {
  //   if (Array.isArray(dateValue) && dateValue.length > 0) {
  //     setDateRange({
  //       fromDate: formatDate(dateValue[0]), // First date in the range
  //       toDate: formatDate(dateValue[dateValue.length - 1]), // Last date in the range
  //     });
  //   }
  // }, [dateValue, successDashboard, dashboard]);

  return (
    <div className="w-full p-4 overflow-hidden border border-gray-300 rounded-lg shadow-xl">
      <div className="flex justify-between mb-4">
        <p className="text-lg font-bold">Shift Scheduling</p>
        <button
          type="button"
          className="w-[70px] h-[30px] px-1 font-bold text-[12px] rounded-[12px] border-2 border-black hover:bg-gray-400"
          onClick={handleClickSeeMore}
        >
          See More
        </button>
      </div>
      <ListInputSelect
        employeeViewInput={viewSelectValue}
        setEmployeeViewInput={setViewSelectValue}
        // dateValue={dateValue}
        // setDateValue={setDateValue}
        // setFieldValue={setFieldValue}
        // setDateRange={setDateRange}
        dataEmployees={dataEmployeesShift}
        keywordValue={keywordName}
        setKeywordValue={setKeywordName}
      />

      <ShiftListBox
        selectView={viewSelectValue}
        dataDate={dateValue}
        dataEmployees={dataEmployeesShift}
        shiftDataTemplate={allShift?.data?.data || []}
        dataAssignShift={dashboard?.data || []}
        locationData={
          locations?.data?.data?.map((item) => {
            return {
              label: item.locationName,
              value: item.uId,
            };
          }) || []
        }
        locationState={locationSelectValue}
        onChangeLocation={(e) => {
          setLocationSelectValue(e.target.value);
        }}
        divHeightEmployee={remainingHeight}
      />
    </div>
  );
}

export default ShiftScheduling;
