import React from "react";
import { InputSelect, InputNumber } from "@bluesilodev/timhutcomponents";
import { FieldArray, useFormikContext } from "formik";
// import { useGetAllEmployee } from "services/employeeApi";

import { FiPlusCircle, FiTrash2 } from "assets/icons";

function EmployeeNeeded({ locationId, selectJob }) {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    handleBlur,
  } = useFormikContext();

  // DUMY JOB POSITION
  // GET ALL EMPLOYEE
  //   const { data: dataEmployees, isLoading: loadingEmployee } =
  //     useGetAllEmployee();

  //   if (loadingEmployee) {
  //     return <div>Loading...</div>;
  //   }

  //   const filterJobBaseLocation =
  //     dataEmployees?.data?.length > 0 &&
  //     dataEmployees?.data?.filter((item) => {
  //       return locationId ? locationId === item.locationID : [];
  //     });

  //   // SELECT INPUT JOBPOSITION
  //   const selectJobPosition =
  //     locationId && filterJobBaseLocation.length > 0
  //       ? filterJobBaseLocation.flatMap((item) => {
  //           return {
  //             value: item.jobPosition,
  //             label: item.jobPosition,
  //           };
  //         })
  //       : [];



  return (
    <FieldArray name="employees">
      {({ push, remove }) => {
        return (
          <div className="flex flex-col gap-3">
            {values.employees.map((item, index) => {
              return (
                <div key={index}>
                  <div className="flex gap-3 items-center ">
                    <div className="w-[90%] flex gap-3 ">
                      <div className="w-full">
                        <InputNumber
                          disable={true}
                          label={"Employee Needed"}
                          name={`employees.${index}.employeesNeeded`}
                          setFieldValue={setFieldValue}
                          value={item.employeesNeeded}
                          onBlur={handleBlur}
                          error={
                            errors.employees &&
                            errors.employees[index] &&
                            errors.employees[index].employeesNeeded &&
                            touched.employees &&
                            touched.employees[index] &&
                            touched.employees[index].employeesNeeded &&
                            errors.employees[index].employeesNeeded
                          }
                        />
                      </div>

                      <InputSelect
                        disabled={true}
                        title={"Job Positions"}
                        id={`employees.${index}.jobPosition`}
                        options={selectJob}
                        value={item.jobPosition}
                        onChange={(e) => {
                          setFieldValue(
                            `employees.${index}.jobPosition`,
                            e.target.value
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `employees.${index}.jobPosition`,
                            true
                          )
                        }
                        error={
                          errors.employees &&
                          errors.employees[index] &&
                          errors.employees[index].jobPosition &&
                          touched.employees &&
                          touched.employees[index] &&
                          touched.employees[index].jobPosition &&
                          errors.employees[index].jobPosition
                        }
                      />
                    </div>

                    {/* {index > 0 && currentRole === "Admin" && (
                      <button
                        type="button"
                        onClick={() => remove(index)}
                        className="min-w-[40px] h-[40px] flex justify-center items-center rounded-full border-[1px] border-black"
                      >
                        <FiTrash2 />
                      </button>
                    )} */}
                  </div>
                </div>
              );
            })}

            {/* {currentRole === "Admin" && (
              <button
                type="button"
                className="bg-gray-200 w-[90%] h-[58px] rounded-md flex justify-center items-center "
                onClick={() => [push({ employeesNeeded: 0, jobPosition: "" })]}
              >
                <div className="flex gap-3">
                  <FiPlusCircle />
                  <h1 className="">Add More Job Positions</h1>
                </div>
              </button>
            )} */}
          </div>
        );
      }}
    </FieldArray>
  );
}

export default EmployeeNeeded;
