import React from "react";
import {
  InputSelect,
  InputDate,
  CheckBox,
  InputNumber,
  Accordion,
  UploadImg1,
} from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";

import { useGetCSCQuery } from "services/commonAPI";

function StepThree() {
  const { values, handleChange, errors, handleBlur, touched, setFieldValue } =
    useFormikContext();

  // GET THE IMAGE
  const changeFile = (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      setFieldValue("attachments", file);
    }
  };

  const { data: resCountry, isLoading: loadingCountry } = useGetCSCQuery();

  if (loadingCountry) {
    return <div>Loading...</div>;
  }

  const dataCountries =
    resCountry &&
    resCountry?.data?.data?.length > 0 &&
    resCountry?.data?.data?.map((item) => {
      return {
        label: item.name,
        value: item.country_code,
      };
    });

  return (
    <div className="w-full">
      <div className="flex w-full justify-center items-center">
        <div className="flex flex-col gap-3 w-full ">
          <div className="flex flex-col gap-3">
            <div>
              <Accordion
                icons={[]}
                title={"Identification Documents"}
                children={
                  <div className="flex flex-col gap-3">
                    <div className="flex gap-3">
                      <InputSelect
                        options={[
                          {
                            label: "Residential Identity Card",
                            value: "Residential Identity Card",
                          },
                          {
                            label: "Driver's License",
                            value: "Driver's License",
                          },
                          {
                            label: "Passport",
                            value: "Passport",
                          },
                          {
                            label: "National Identity Card",
                            value: "National Identity Card",
                          },
                        ]}
                        title={"Identification Type"}
                        required={true}
                        name="identificationType"
                        value={values.identificationType}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          <div>
                            {touched.identificationType &&
                              errors.identificationType &&
                              errors.identificationType}
                          </div>
                        }
                      />
                      <InputNumber
                        label={"Identity Number"}
                        unit={"Day(s)"}
                        name={"identityNumber"}
                        onBlur={handleBlur}
                        value={values.identityNumber}
                        error={
                          errors.identityNumber &&
                          touched.identityNumber &&
                          errors.identityNumber
                        }
                        setFieldValue={setFieldValue}
                      />
                    </div>

                    <div className="flex gap-3">
                      <div className="w-[50%]">
                        <InputDate
                          label={"Identity Expired Date"}
                          setFieldValue={setFieldValue}
                          name={"identityExpiredDate"}
                          required={true}
                          onChange={handleChange}
                          disabled={values.isPermanentDate}
                          onBlur={handleBlur}
                          value={values.identityExpiredDate}
                          errors={
                            errors.identityExpiredDate &&
                            touched.identityExpiredDate &&
                            errors.identityExpiredDate
                          }
                        />
                      </div>

                      <CheckBox
                        label={"Permanent Date"}
                        value={values.isPermanentDate}
                        nameInput={"isPermanentDate"}
                        // disabled={disabled}
                        onChange={(v) => {
                          setFieldValue("isPermanentDate", v);
                        }}
                      />
                    </div>

                    <div className="flex gap-3 mt-2">
                      <InputSelect
                        title={"Nationality"}
                        options={dataCountries}
                        required={true}
                        name="nationality"
                        value={values.nationality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.nationality &&
                          errors.nationality &&
                          errors.nationality
                        }
                      />

                      <InputSelect
                        title={"Covid-19 Vacination Status"}
                        options={[
                          {
                            label: "Fully Vacinated",
                            value: "Fully Vacinated",
                          },
                          {
                            label: "Partially Vacinated",
                            value: "Partially Vacinated",
                          },
                          {
                            label: "Not Vacinated",
                            value: "Not Vacinated",
                          },
                        ]}
                        required={true}
                        name="covid19VactionStatus"
                        value={values.covid19VactionStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.covid19VactionStatus &&
                          errors.covid19VactionStatus &&
                          errors.covid19VactionStatus
                        }
                      />
                    </div>
                  </div>
                }
              />
            </div>

            <div>
              <Accordion
                icons={[]}
                title={"Attachments"}
                children={
                  <div className="w-full pt-3 ">
                    <UploadImg1
                      onChange={changeFile}
                      textSizeLimit={
                        "For Best Resolution is 1366 x 1366px, Max upload image size is 2MB, Supported files: .jpg, .png "
                      }
                      title={"Attachment"}
                      onBlur={handleBlur}
                      id="attachments"
                      accept="image/jpeg, image/jpg, image/png"
                      message={
                        touched.attachments && errors.attachments ? (
                          <div className="text-red-500 ">
                            {errors.attachments}
                          </div>
                        ) : (
                          (values?.attachments &&
                            values?.attachments[0]?.name) ||
                          values?.attachments?.name
                        )
                      }
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StepThree;
