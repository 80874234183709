import React from "react";

function EventCalendarDate({ date }) {
  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <rect
          x="0.5"
          y="0.5"
          width="47"
          height="47"
          rx="23.5"
          stroke="#E3E3E3"
        />
        <path
          opacity="0.15"
          d="M10.0745 80.11C20.0745 80.2815 20.4347 80.4514 21.1345 80.6098C21.8343 80.7683 22.86 80.9123 24.1531 81.0336C25.4462 81.1549 26.9813 81.2511 28.6709 81.3168C30.3604 81.3824 32.1712 81.4162 33.9999 81.4162C35.8286 81.4162 37.6394 81.3824 39.3289 81.3168C41.0184 81.2511 42.5535 81.1549 43.8466 81.0336C45.1397 80.9123 46.1655 80.7683 46.8653 80.6098C47.5651 80.4514 47.9253 80.2815 47.9253 80.11C47.9253 79.7635 46.4582 79.4313 43.8466 79.1863C41.2351 38.9413 37.6931 38.8037 33.9999 38.8037C30.3066 38.8037 26.7646 38.9413 24.1531 39.1863C21.5416 39.4313 20.0745 39.7635 20.0745 40.11Z"
          fill="#1A1A2E"
        />

        <path
          d="M11.8162 33.3418H36.1837V36.8251C36.1837 37.518 35.9084 38.1825 35.4185 38.6724C34.9285 39.1624 34.264 39.4376 33.5712 39.4376H14.4287C13.7358 39.4376 13.0713 39.1624 12.5813 38.6724C12.0914 38.1825 11.8162 37.518 11.8162 36.8251V33.3418Z"
          fill="#B8B8B8"
          stroke="#1A1A2E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8162 31.5996H36.1837V35.0829C36.1837 35.7758 35.9084 36.4403 35.4185 36.9303C34.9285 37.4202 34.264 37.6954 33.5712 37.6954H14.4287C13.7358 37.6954 13.0713 37.4202 12.5813 36.9303C12.0914 36.4403 11.8162 35.7758 11.8162 35.0829V31.5996Z"
          fill="#B8B8B8"
          stroke="#1A1A2E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8162 17.667H36.1837V33.342C36.1837 34.0349 35.9084 34.6994 35.4185 35.1893C34.9285 35.6792 34.264 35.9545 33.5712 35.9545H14.4287C13.7358 35.9545 13.0713 35.6792 12.5813 35.1893C12.0914 34.6994 11.8162 34.0349 11.8162 33.342V17.667Z"
          fill="#FDFDFD"
          stroke="#1A1A2E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4288 10.2803H33.5713C34.2641 10.2803 34.9286 10.5555 35.4186 11.0455C35.9085 11.5354 36.1838 12.1999 36.1838 12.8928V17.6428H11.8163V12.9165C11.8131 12.5715 11.8784 12.2292 12.0083 11.9095C12.1382 11.5898 12.3301 11.2989 12.573 11.0538C12.8159 10.8087 13.105 10.6142 13.4235 10.4814C13.742 10.3486 14.0837 10.2803 14.4288 10.2803Z"
          fill="#B83C3C"
        />
        <path
          d="M33.5714 10.2803H14.4289C14.0835 10.2792 13.7413 10.3468 13.4223 10.4792C13.1033 10.6116 12.8138 10.8062 12.5707 11.0515C12.3276 11.2968 12.1357 11.5881 12.0062 11.9082C11.8767 12.2284 11.8122 12.5712 11.8164 12.9165V15.0936C11.8153 14.7502 11.8822 14.4101 12.0131 14.0926C12.144 13.7752 12.3364 13.4868 12.5792 13.244C12.822 13.0012 13.1104 12.8088 13.4279 12.6779C13.7453 12.5469 14.0855 12.4801 14.4289 12.4811H33.5714C33.9147 12.4801 34.2549 12.5469 34.5724 12.6779C34.8898 12.8088 35.1782 13.0012 35.421 13.244C35.6638 13.4868 35.8562 13.7752 35.9871 14.0926C36.118 14.4101 36.1849 14.7502 36.1839 15.0936V12.9165C36.1881 12.5712 36.1235 12.2284 35.994 11.9082C35.8645 11.5881 35.6726 11.2968 35.4295 11.0515C35.1864 10.8062 34.8969 10.6116 34.5779 10.4792C34.2589 10.3468 33.9167 10.2792 33.5714 10.2803Z"
          fill="#DD4848"
        />
        <path
          d="M14.4288 10.2803H33.5713C34.2641 10.2803 34.9287 10.5555 35.4186 11.0455C35.9085 11.5354 36.1838 12.1999 36.1838 12.8928V17.6428H11.8163V12.9165C11.8131 12.5715 11.8784 12.2292 12.0083 11.9095C12.1382 11.5898 12.3301 11.2989 12.573 11.0538C12.8159 10.8087 13.105 10.6142 13.4235 10.4814C13.742 10.3486 14.0837 10.2803 14.4288 10.2803Z"
          stroke="#1A1A2E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <text
          x="24"
          y="24"
          text-anchor="middle"
          dominant-baseline="middle"
          font-size="14"
          fill="#1A1A2E"
          font-weight="bold"
          dy="3"
        >
          {date}
        </text>
      </svg>
    </div>
  );
}

export default EventCalendarDate;
