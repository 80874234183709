import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";

export const capitalize_first_letter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function formatCalendarDate(dateString) {
  if (!dateString) return "dd/mm/yy"; // Jika null atau undefined, return default

  const formattedDate = dayjs(dateString, "MM/DD/YYYY");

  if (formattedDate.isValid()) {
    return formattedDate.format("DD/MM/YYYY");
  } else {
    return dayjs(dateString).format("DD/MM/YYYY");
  }
}

export function formatToMidnight(dateString) {
  // Mengonversi string tanggal menjadi objek Date
  const date = new Date(dateString);

  // Mengambil tahun, bulan, dan tanggal dari objek Date
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
  const day = String(date.getUTCDate()).padStart(2, "0");

  // Menetapkan waktu ke tengah malam
  const hours = "00";
  const minutes = "00";
  const seconds = "00";
  const milliseconds = "000";

  // Membuat format waktu yang diinginkan
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}
