import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

// layout
import LayoutDashboard from "layouts/dashboard";
// pages
import AdminDashboardPage from "pages/admin/dashboard";
import LoginPage from "pages/auth/login";
import RegisterPage from "pages/auth/register/signUpByCompany";
import SignUpByEmployee from "pages/auth/register/signUpByEmployee";
import DashboardPage from "pages/dashboard";
import EmployeeDashboardPage from "pages/user/dashboard";
// import CobaPage from "coba";

// reset password pages
import {
  ConfirmationEmailPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from "pages/auth/forgotPassword";
import { PunchWidget } from "pages/user/widgets";

const AppRouter = () => {
  const { currentUser } = useSelector((state) => {
    const userData = state.userData || {};
    return { currentUser: userData.currentUser || "" };
  });
  //   const [currentMonth, setCurrentMonth] = useState(() => {
  //     const now = new Date();
  //     return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
  // });
  // const [dateData, setDateData] = useState(0);
  // console.log(dateData);
  // const [dashboardData, setDashboardData] = useState({
  //     eventData: [],
  //     birthdayData: [],
  //     leaveData: [],
  // });

  // const { data: eventRespData, refetch: refetchEvent } = useGetEventQuery();
  // const { data: birthdayRespData, refetch: refetchBirthday } = useGetBirthdayQuery();
  // const { data: leaveRespData, refetch: refetchLeave } = useGetLeaveQuery();

  // useEffect(() => {
  //     if (eventRespData && eventRespData.success) {
  //         setDashboardData(prevData => ({
  //             ...prevData,
  //             eventData: eventRespData.data.data,
  //         }));
  //     }
  //     if (birthdayRespData && birthdayRespData.success) {
  //         setDashboardData(prevData => ({
  //             ...prevData,
  //             birthdayData: birthdayRespData.data.data,
  //         }));
  //     }
  //     if (leaveRespData && leaveRespData.success) {
  //         setDashboardData(prevData => ({
  //             ...prevData,
  //             leaveData: leaveRespData.data.data,
  //         }));
  //     }
  // }, [eventRespData, birthdayRespData, leaveRespData]);

  // useEffect(() => {
  //     const getTimeUntilNextMonth = () => {
  //         const now = new Date();
  //         const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  //         return nextMonth - now;
  //     };

  //     // Set timeout to update the month at the beginning of the next month
  //     const timeoutId = setTimeout(() => {
  //         const now = new Date();
  //         const newMonth = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
  //         setCurrentMonth(newMonth);
  //     }, getTimeUntilNextMonth());

  //     // Cleanup timeout on component unmount
  //     return () => clearTimeout(timeoutId);
  // }, [currentMonth]);

  // useEffect(() => {
  //     // Refetch calendar data when the current month changes
  //     refetchEvent();
  //     refetchBirthday();
  //     refetchLeave();
  // }, [currentMonth, refetchEvent, refetchBirthday, refetchLeave]);

  // const transformedEventData = dashboardData?.eventData?.length > 0
  //         ? dashboardData?.eventData?.map(event => ({
  //             imgSrc: EventCalendarIcon,
  //             name: event.nameEvent,
  //             date: event.startDate,
  //         }))
  //     : [];

  //     const transformedBirthdayData = dashboardData?.birthdayData?.length > 0
  //         ? dashboardData?.birthdayData?.map(birthday => ({
  //             imgSrc: EventCalendarIcon,
  //             name: birthday.name,
  //             date: birthday.dateOfBirth,
  //         }))
  //     : [];

  //     const transformedLeaveData = dashboardData?.leaveData?.length > 0
  //         ? dashboardData?.leaveData?.map(leave => {
  //         // Get the newest date from leaveTimes
  //             const latestLeaveTime = leave.leaveTimes.reduce((latest, current) => {
  //                 return new Date(current.date) > new Date(latest.date) ? current : latest;
  //             }, leave.leaveTimes[0]);

  //             return {
  //                 imgSrc: EventCalendarIcon,
  //                 name: leave?.leaveType?.name,
  //                 date: latestLeaveTime.date,
  //                 employeeName: leave?.employeeInformation?.firstName,
  //             };
  //         })
  //     : [];

  //     const exampleOptions = [
  //         {
  //             id: '1',
  //             value: 'Events',
  //             dataList: transformedEventData
  //         },
  //         {
  //             id: '2',
  //             value: 'On Leave',
  //            dataList: transformedLeaveData
  //         },
  //         {
  //             id: '3',
  //             value: 'Birthday',
  //             dataList: transformedBirthdayData
  //         },
  //     ];

  return (
    <Router basename={window.__POWERED_BY_QIANKUN__ ? "/dashboard" : "/"}>
      <Routes>
        {/* <Route path="/coba" element={<CobaPage />} /> */}

        <Route
          path="/timer"
          element={<PunchWidget handleOpenModal={() => {}} />}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/registerCompany" element={<RegisterPage />} />
        <Route path="/registerEmployee" element={<SignUpByEmployee />} />
        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
        <Route path="/confirmEmail" element={<ConfirmationEmailPage />} />
        <Route
          path="/resetPassword/:uId/:token"
          element={<ResetPasswordPage />}
        />
        <Route element={<LayoutDashboard />}>
          <Route
            path="/"
            element={<DashboardPage currentUser={currentUser} />}
          />
          <Route
            path="/admindashboard"
            element={
              <AdminDashboardPage
                currentUser={currentUser?.userName}
                //   currentMonth={currentMonth}
              />
            }
          />
          <Route
            path="/employeedashboard"
            element={
              <EmployeeDashboardPage
                currentUser={currentUser?.userName}
                //    setDateData={setDateData}
                //    exampleOptions={exampleOptions}
                //    day={dateData}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
