import * as yup from "yup";

export const editEventSchema = yup.object().shape({
  nameEvent: yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]+$/,
      "Only letters, numbers, spaces, underscores, and dashes are allowed"
    )
    .required("Name Of Event is Required"),
  startDate: yup
    .string()
    .required("Start Date is required")
    .test(
      "more than",
      "Start Date must be greater than End Date",
      (val, context) => {
        const contextDate = context.parent.endDate;

        if (contextDate && val) {
          const [startDay, startMonth, startYear] = val.split("/");
          const joinDate = new Date(startYear, startMonth - 1, startDay);

          const [endDay, endMonth, endYear] = contextDate.split("/");
          const endDate = new Date(endYear, endMonth - 1, endDay);

          return endDate > joinDate;
        }
        return true;
      }
    ),

  endDate: yup
    .string()
    .required("End Date is required")
    .test(
      "is-greater",
      "End Date must be less than Start Date",
      function (val, context) {
        // 'this' refers to the entire object being validated
        const contextDate = context.parent.startDate;

        if (contextDate && val) {
          const [startDay, startMonth, startYear] = val.split("/");
          const endDate = new Date(startYear, startMonth - 1, startDay);

          const [endDay, endMonth, endYear] = contextDate.split("/");
          const joinDate = new Date(endYear, endMonth - 1, endDay);

          return endDate > joinDate;
        }
        return true;
      }
    ),
  posterAttachment: yup
    .mixed()
    .required("You need to provide a file")
    .test("fileSize", "The file is too large", (value) => {
      if (value && value.size >= 2000000) {
        return false;
      }
      return true;
    })
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        if (
          value &&
          (!value.type === "image/jpeg" ||
            !value.type === "image/png" ||
            !value.type === "image/jpg")
        ) {
          return false;
        }
        return true;
      }
    ),
  location: yup.string().required("Location is Required"),
});
