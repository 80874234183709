import axios from "axios";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";

import { Button, InputText, TextArea } from "@bluesilodev/timhutcomponents";

import CameraComponent from "components/camera";
import LocationComponent from "components/locationTracking";
import TestInputSelect from "components/testInputSelect";
import {
  handlePostPunchOut,
  useGetAttendanceByUser,
} from "services/attendanceAPI";
import { extractPostalCode } from "utils/utils";

import CloseIcon from "assets/close.svg";
import TimeIcon from "assets/time.svg";

import { useQueryClient } from "@tanstack/react-query";
import { alertError, alertSuccess } from "utils/alert";
import { validationSchema } from "./schema";

const PunchOutForm = ({ onClose, attendanceFormData, shiftOptionList }) => {
  const queryClient = useQueryClient();
  const [currentUserLocation, setCurrentUserLocation] = useState({
    address: "",
    lat: "",
    long: "",
    postalCode: "",
  });
  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const { data: userAttendance } = useGetAttendanceByUser();

  const currentShift = userAttendance?.data?.currentData ?? null;

  useEffect(() => {
    if (coords) {
      const fetchAddress = async () => {
        const { latitude, longitude } = coords;
        const apiKey = process.env.REACT_APP_TOM_TOM_API_KEY;
        const url = `https://api.tomtom.com/search/2/reverseGeocode/${latitude},${longitude}.json?key=${apiKey}`;

        try {
          const response = await axios.get(url);
          const addressData = response.data.addresses[0].address;
          const address = addressData.freeformAddress;
          const postalCode =
            addressData.postalCode || extractPostalCode(address);
          setCurrentUserLocation({
            address: address,
            lat: latitude,
            long: longitude,
            postalCode: postalCode,
          });
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      };

      fetchAddress();
    }
  }, [coords]);

  // const { mutate, isLoading } = usePostPunchOutDataMutation(handleFormSubmit, onClose, dispatch);

  const buttonLabel = (
    <div className="flex justify-center w-full gap-2">
      <img src={TimeIcon} alt="Time Icon" />
      <p className="text-sm">Punch Out</p>
    </div>
  );
  const cancellabel = (
    <div className="flex justify-center w-full gap-2">
      <img src={CloseIcon} alt="Close Icon" />
      <p className="text-sm">Cancel</p>
    </div>
  );

  return (
    <Formik
      initialValues={{
        shift: currentShift?.scheduleID || "",
        location:
          attendanceFormData?.[currentShift?.scheduleID]?.location?.value || "",
        jobPosition:
          attendanceFormData?.[currentShift?.scheduleID]?.jobPosition?.value ||
          "",
        punchOutDesc: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        try {
          const formData = new FormData();

          for (const key in values) {
            formData.append(key, values[key]);
          }

          formData.append("punchOutGps", JSON.stringify(currentUserLocation));

          const resp = await handlePostPunchOut(formData);
          if (resp.data && resp.data.data.punchOut) {
            await queryClient.invalidateQueries(["getAttendanceData"]);
          }
          alertSuccess("Success Sending Employee Punch Out Data");
          onClose();
        } catch (error) {
          console.error("Error during punch out submission:", error);
          alertError(
            `Failed to send Employee Punch Out Data: ${
              error.response.data.message || ""
            }`
          );
        }
      }}
    >
      {({ handleChange, values, errors, touched, setFieldValue }) => {
        return (
          <Form>
            <div className="grid grid-cols-2 gap-6 p-3 form-group">
              <div className="col-span-1">
                <TestInputSelect
                  name="shift"
                  title="Shift"
                  options={
                    shiftOptionList && shiftOptionList.length > 0
                      ? shiftOptionList
                      : []
                  }
                  className="h-[20px]"
                  value={values.shift}
                  label={null}
                  disabled
                />
              </div>
              <div className="col-span-1" />
              <div className="col-span-1">
                <InputText
                  name="location"
                  title="Location"
                  onChange={handleChange}
                  value={values.location}
                  disabled
                  label={null}
                />
              </div>
              <div className="col-span-1">
                <InputText
                  name="jobPosition"
                  title="Job Position"
                  value={values.jobPosition}
                  onChange={handleChange}
                  disabled
                  label={null}
                />
              </div>
              <div className="col-span-2">
                <LocationComponent
                  address={currentUserLocation.address}
                  coords={coords}
                />
              </div>
              <div className="col-span-2 row-span-3">
                <CameraComponent
                  setFieldValue={setFieldValue}
                  imageName="punchOutImage"
                />
              </div>
            </div>
            <div className="col-span-2 mt-4">
              <TextArea
                name="punchOutDesc"
                label="Description"
                rows={4}
                value={values.punchOutDesc}
                onChange={handleChange}
                error={
                  touched.punchOutDesc && errors.punchOutDesc
                    ? errors.punchOutDesc
                    : ""
                }
              />
            </div>
            <div className="grid w-full grid-cols-2 gap-6 mt-4">
              <Button
                onClick={onClose}
                type="button"
                className="h-[50px]"
                label={cancellabel}
              />
              <Button
                type="submit"
                style="solid"
                className="h-[50px]"
                label={buttonLabel}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PunchOutForm;
