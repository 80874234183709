import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useParams } from "react-router-dom";

import {
    Onboarding,
    Button,
    InputPassword,
} from "@bluesilodev/timhutcomponents";
import { handlePostResetPassword } from "services/employeeAPI";
import { alertSuccess, alertError } from "utils/alert";
import ImgTimhut from "assets/images/logo_login.png";

function ResetPasswordPage() {
    const navigate = useNavigate();
    const { uId, token } = useParams();

    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
    } = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: yup.object({
            password: yup.string().min(6).max(20).required("Password is Required"),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref("password"), null], "Password value must be the same")
                .required("Confirm Password is Required")
                .min(6, "Minimum 6 Character")
        }),

        onSubmit: async (val) => {
            try {
                // console.log(uId, token);
                const body = {
                    uId: uId,
                    token: token,
                    password: val.password,
                };
                const resp = await handlePostResetPassword(body);
                if (resp && resp.success === true) {
                    alertSuccess('Successfully reset password');
                    navigate("/login");
                } else {
                    alertError('Failed to reset password');
                }

            } catch (error) {
                alertError('Reset Password Error: Something went wrong');
            }

        },
    });

    return (
        <>
            <Onboarding source={ImgTimhut}>
                <div className="w-full flex justify-end">
                    <Link to={"/login"}>
                        <Button
                            className={"w-[200px] mt-10 mr-4"}
                            label={
                                <div className="flex items-center gap-3 ">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.33331 8.00065V6.16241C3.33331 5.39131 3.33331 5.00576 3.43822 4.69476C3.63781 4.10302 4.10235 3.63848 4.69408 3.43889C5.00509 3.33398 5.39064 3.33398 6.16174 3.33398V3.33398C6.49918 3.33398 6.66791 3.33398 6.82878 3.36038C7.13279 3.41028 7.42116 3.52972 7.67141 3.70941C7.80383 3.8045 7.92313 3.9238 8.16174 4.16241L8.36682 4.36749C8.74159 4.74226 8.92898 4.92965 9.15037 5.05942C9.30876 5.15226 9.47918 5.22285 9.65682 5.2692C9.90512 5.33398 10.1701 5.33398 10.7001 5.33398V5.33398C11.9146 5.33398 12.5218 5.33398 12.9771 5.58893C13.299 5.76918 13.5648 6.03495 13.745 6.35686C14 6.81216 14 7.41938 14 8.63382V9.33398C14 10.5801 14 11.2032 13.732 11.6673C13.5565 11.9714 13.304 12.2238 13 12.3994C12.5359 12.6673 11.9128 12.6673 10.6666 12.6673V12.6673"
                                            stroke="#FDFDFD"
                                            strokeWidth="2"
                                        />
                                        <path
                                            d="M2.66669 10.9993H8.66669M8.66669 10.9993L6.33335 8.66602M8.66669 10.9993L6.33335 13.3327"
                                            stroke="#FDFDFD"
                                            strokeWidth="2"
                                        />
                                    </svg>

                                    <h1>Sign In</h1>
                                </div>
                            }
                            type={"button"}
                            style="solid"
                            width={200}
                        />
                    </Link>
                </div>

                <form
                    autoComplete="off"
                    className="w-full flex flex-col px-10 py-5"
                    onSubmit={handleSubmit}
                >
                    <div className="my-5">
                        <h1 className="font-bold text-2xl">Reset Password</h1>
                        <p className="font-semibold mt-2">Enter your New Password</p>
                    </div>
                    <div className="flex flex-col gap-3 w-full">
                        <div className="flex flex-col gap-3">
                            <InputPassword
                                id={"password"}
                                title={"Password"}
                                name={"password"}
                                onChange={handleChange}
                                value={values.password}
                                label={
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                                            stroke="#1A1A2E"
                                            strokeWidth="2"
                                        />
                                        <path
                                            d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                                            stroke="#1A1A2E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                        <circle cx="12" cy="15" r="2" fill="#1A1A2E" />
                                    </svg>
                                }
                                className={"flex items-center w-full gap-2"}
                                onBlur={handleBlur}
                                required={true}
                                error={
                                    <>
                                        {errors.password && touched.password && (
                                            <p className="text-red-500">{errors.password}</p>
                                        )}
                                    </>
                                }
                                icon={
                                    <>
                                        <div>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                                                    stroke="#1A1A2E"
                                                    strokeWidth="2"
                                                />
                                                <path
                                                    d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                                                    stroke="#1A1A2E"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                                <circle cx="12" cy="15" r="2" fill="#1A1A2E" />
                                            </svg>
                                        </div>
                                    </>
                                }
                            />
                            <InputPassword
                                id={"confirmPassword"}
                                title={"Confirm Password"}
                                name={"confirmPassword"}
                                onChange={handleChange}
                                value={values.confirmPassword}
                                label={
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                                            stroke="#1A1A2E"
                                            strokeWidth="2"
                                        />
                                        <path
                                            d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                                            stroke="#1A1A2E"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                        />
                                        <circle cx="12" cy="15" r="2" fill="#1A1A2E" />
                                    </svg>
                                }
                                className={"flex items-center w-full gap-2"}
                                onBlur={handleBlur}
                                required={true}
                                error={
                                    <>
                                        {errors.confirmPassword && touched.confirmPassword && (
                                            <p className="text-red-500">{errors.confirmPassword}</p>
                                        )}
                                    </>
                                }
                                icon={
                                    <>
                                        <div>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M4 13C4 11.1144 4 10.1716 4.58579 9.58579C5.17157 9 6.11438 9 8 9H16C17.8856 9 18.8284 9 19.4142 9.58579C20 10.1716 20 11.1144 20 13V15C20 17.8284 20 19.2426 19.1213 20.1213C18.2426 21 16.8284 21 14 21H10C7.17157 21 5.75736 21 4.87868 20.1213C4 19.2426 4 17.8284 4 15V13Z"
                                                    stroke="#1A1A2E"
                                                    strokeWidth="2"
                                                />
                                                <path
                                                    d="M16 8V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7V8"
                                                    stroke="#1A1A2E"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                                <circle cx="12" cy="15" r="2" fill="#1A1A2E" />
                                            </svg>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </div>

                    <Button
                        className={"w-full mt-5"}
                        style="solid"
                        type="submit"
                        label={
                            <div className="flex items-center gap-3 ">
                                <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.33331 8.00065V6.16241C3.33331 5.39131 3.33331 5.00576 3.43822 4.69476C3.63781 4.10302 4.10235 3.63848 4.69408 3.43889C5.00509 3.33398 5.39064 3.33398 6.16174 3.33398V3.33398C6.49918 3.33398 6.66791 3.33398 6.82878 3.36038C7.13279 3.41028 7.42116 3.52972 7.67141 3.70941C7.80383 3.8045 7.92313 3.9238 8.16174 4.16241L8.36682 4.36749C8.74159 4.74226 8.92898 4.92965 9.15037 5.05942C9.30876 5.15226 9.47918 5.22285 9.65682 5.2692C9.90512 5.33398 10.1701 5.33398 10.7001 5.33398V5.33398C11.9146 5.33398 12.5218 5.33398 12.9771 5.58893C13.299 5.76918 13.5648 6.03495 13.745 6.35686C14 6.81216 14 7.41938 14 8.63382V9.33398C14 10.5801 14 11.2032 13.732 11.6673C13.5565 11.9714 13.304 12.2238 13 12.3994C12.5359 12.6673 11.9128 12.6673 10.6666 12.6673V12.6673"
                                        stroke="#FDFDFD"
                                        strokeWidth="2"
                                    />
                                    <path
                                        d="M2.66669 10.9993H8.66669M8.66669 10.9993L6.33335 8.66602M8.66669 10.9993L6.33335 13.3327"
                                        stroke="#FDFDFD"
                                        strokeWidth="2"
                                    />
                                </svg>

                                <h1>Submit</h1>
                            </div>
                        }
                    />
                </form>
            </Onboarding>
        </>
    );
}

export default ResetPasswordPage;
